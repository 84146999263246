<script lang="ts">
	import { closeOverlay } from "../../../overlay"
	import {
		MediaTrimController,
		type MediaToolOptions,
		BackgroundRemoveController,
	} from "../MediaToolController"
	import MediaToolSaveVerifier from "./MediaTool_Save.svelte"
	import LoadingSpinner from "../../../loading-indicator/LoadingSpinner.svelte"
	import { MediaAlterError } from "luxedo-data"

	interface Props {
		tool: MediaToolOptions
		children?: import("svelte").Snippet
	}

	let { tool, children }: Props = $props()

	let isVerifyingSave = $state(false)
	let isWaiting = $state(false)
	let loadingProgress: number = $state()

	let error: string = $state()

	function updateProgress(progress: number) {
		loadingProgress = progress
	}

	async function handleSave() {
		if (isVerifyingSave) {
			error = undefined
			isWaiting = true
			try {
				switch (tool) {
					case "bg-remove":
						await BackgroundRemoveController.saveInPlace(updateProgress)
						break
					case "trim":
						await MediaTrimController.saveInPlace(updateProgress)
						break
					case "draw":
						break
				}
			} catch (e) {
				console.error("[ERROR] ", e)
				if (e instanceof MediaAlterError) {
					error = e.message
				} else {
					error = "Unable to modify media, please try again."
				}
			}
			isWaiting = false
		} else {
			isVerifyingSave = true
		}
	}

	function cancelVerify() {
		isVerifyingSave = false
	}

	function cancel() {
		if (error) closeOverlay()
		else if (isVerifyingSave) cancelVerify()
		else closeOverlay()
	}
</script>

<div id="media-editor">
	{#if isVerifyingSave}
		<MediaToolSaveVerifier progress={loadingProgress} {error} />
	{:else}
		<div id="tool-container">
			{@render children?.()}
		</div>
	{/if}

	<div class="button-container">
		<button class="link-button" onclick={cancel}>Cancel</button>
		{#if isWaiting}
			<LoadingSpinner color="var(--color-main)" height="2rem" />
		{:else if !error}
			<button onclick={handleSave}>Save</button>
		{/if}
	</div>
</div>

<style>
	#media-editor {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		margin-bottom: 0.5rem;
		height: 100%;
	}

	#media-editor :global(img),
	#media-editor :global(video),
	#media-editor :global(canvas) {
		overflow: hidden;
		border-radius: var(--br);
		max-height: 40vh;
		max-width: 60vw;
	}

	#media-editor :global(.color-picker .wrapper) {
		background-color: var(--color-bg);
	}

	#media-editor :global(.color-picker .wrapper .text-input input) {
		background-color: transparent;
	}

	#media-editor :global(.color-picker label) {
		display: flex;
		flex-direction: column-reverse;
	}

	#media-editor :global(.color-picker label .container) {
		margin-right: 0;
		border-radius: 100%;
		overflow: hidden;
		height: 1rem;
		width: 1rem;
	}

	#media-editor :global(input[type="range"]) {
		width: unset;
		flex-grow: 1;
		margin-left: 1rem;
		padding: 0;
	}

	.button-container {
		position: absolute;
		bottom: 1rem;
		right: 3rem;
		margin: 0;
		justify-content: flex-end;
	}

	#tool-container {
		display: flex;
		flex-direction: column;
	}
</style>
