<script lang="ts">
	import type { Calibration } from "luxedo-data"
	import MaskEditorCanvas from "./MaskEditor_Canvas.svelte"
	import MaskEditorSidebar from "./sidebar/MaskEditorSidebar.svelte"
	import { onDestroy, onMount } from "svelte"
	import { MaskEditorController } from "./MaskEditorController"
	import { MaskSidebarController } from "./sidebar/MaskEditorSidebarController"
	import LoadingSpinner from "../../loaders/LoadingSpinner.svelte"

	const MENU_CAN_SAVE: Array<MaskSidebarController.MenuOption> = ["list"]

	interface Props {
		calibration: Calibration;
	}

	let { calibration }: Props = $props();

	let activeMenu: MaskSidebarController.MenuOption = $state()
	let isSaving: boolean = $state(false)

	MaskSidebarController.subscribe((ctx) => {
		activeMenu = ctx.menu
	})

	async function save() {
		isSaving = true
		await MaskEditorController.save()
		isSaving = false
	}

	onMount(() => {
		MaskEditorController.initialize(calibration)
	})

	onDestroy(() => {
		MaskEditorController.reset()
	})
</script>

<div id="mask-editor">
	<MaskEditorSidebar />
	<MaskEditorCanvas />
	{#if MENU_CAN_SAVE.includes(activeMenu)}
		<div id="save-button-container">
			{#if isSaving}
				<LoadingSpinner height="2rem" color="var(--color-main)" />
			{:else}
				<button id="save-masks-button" onclick={save}>Save</button>
			{/if}
		</div>
	{/if}
</div>

<style>
	#mask-editor {
		--sidebar-width: 20rem;
		--mask-preview-height: 8rem;

		height: 80vh;
		width: 80vw;
		display: flex;
		flex-direction: row;
	}

	#save-button-container {
		position: absolute;
		bottom: 1rem;
		right: 1rem;
	}
</style>
