<script lang="ts">
	import { Route } from "svelte-routing"
	import { onMount } from "svelte"

	import LoadingPage from "../loaders/LoadingPage.svelte"
	import { pageLoader } from "../../../modules/auth/onUserLogin"
	import type { Component } from "../../../types/SvelteTypes"

	interface Props {
		component: Component
		path: string
	}

	let { component, path }: Props = $props()

	let redirectPath: string = "/login"
	let isLoading = $state(true)

	function redirect() {
		window.location.replace("/login")
	}

	onMount(async () => {
		if (redirectPath === path)
			throw new Error("Cannot set a redirect path to be the same as path - a loop will occur.")
	})

	async function onRouteMount() {
		const isUserLoggedIn = await pageLoader.initPageLoad()
		if (!isUserLoggedIn) return redirect()
		isLoading = false
	}
</script>

<Route {path}>
	{#snippet children({ params })}
		{#if isLoading}
			<LoadingPage {onRouteMount} />
		{:else}
			{@const SvelteComponent = component}
			<SvelteComponent {params} />
		{/if}
	{/snippet}
</Route>

<style>
</style>
