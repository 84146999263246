<script lang="ts">
	import LoadingSpinner from "../loading-indicator/LoadingSpinner.svelte"

	// the duration needed to trigger the hold action
	const HOLD_DURATION = 800

	let isHeld: boolean = $state(false)
	let isHolding: boolean = $state(false)

	let staticWidth: number = $state()
	let initialWidth: number = $state()

	interface Props {
		labelDefault: string
		labelHold: string
		actionDefault: () => void
		actionHold: () => void
		id?: string
		disabled?: boolean
		isLoading?: boolean
	}

	let {
		labelDefault,
		labelHold,
		actionDefault,
		actionHold,
		id = undefined,
		disabled = false,
		isLoading = false,
	}: Props = $props()

	let timeout: number

	/**
	 * Sets isHolding to true and sets a timeout to set isHeld to true after the HOLD_DURATION
	 */
	function handleMouseDown() {
		if (timeout) clearTimeout(timeout)
		isHolding = true

		const mouseUp = () => {
			handleMouseUp(true)
			document.removeEventListener("mouseup", mouseUp)
			document.removeEventListener("touchend", mouseUp)
		}

		document.addEventListener("mouseup", mouseUp)
		document.addEventListener("touchend", mouseUp)

		// once held for the hold duration, isHeld gets set to true
		timeout = window.setTimeout(() => {
			isHeld = true

			isHolding = false
			timeout = undefined
		}, HOLD_DURATION)
	}

	/**
	 * Called when the mouse button is released. Resets the hold state.
	 */
	function handleMouseUp(ignoreActions?: boolean) {
		if (!ignoreActions) {
			if (isHeld) actionHold()
			else actionDefault()
		}

		isHolding = false

		if (timeout) clearTimeout(timeout)
		timeout = undefined

		// wrapped in timeout for css animation reasons
		setTimeout(() => {
			isHeld = false
		})
	}

	let hasInitialized = false
	function onWidthUpdate(width: number) {
		if (hasInitialized || width === undefined) return

		staticWidth = width + 10
		hasInitialized = true
	}

	$effect(() => {
		if (initialWidth) onWidthUpdate(initialWidth)
	})
</script>

{#if isLoading}
	<LoadingSpinner height="2rem" color="var(--color-main)" />
{:else}
	<button
		{id}
		class="outline-button {isHolding ? 'holding' : ''} {isHeld ? 'held' : ''} hold-button"
		style="width: {staticWidth}px"
		bind:clientWidth={initialWidth}
		onmousedown={handleMouseDown}
		ontouchstart={handleMouseDown}
		onmouseup={() => handleMouseUp(false)}
		ontouchend={() => handleMouseUp(false)}
		{disabled}
	>
		<!-- on:contextmenu|preventDefault -->
		{labelDefault}

		<div class="hold-button-background"></div>
		<span class="hold-label">
			{labelHold}
		</span>
	</button>
{/if}

<style>
	.hold-button {
		background-color: unset;
		position: relative;
		overflow: hidden;
	}

	.hold-button:hover {
		background-color: var(--color-main-transparent);
	}

	.hold-label {
		position: absolute;
		top: 0;
		left: 0;
		bottom: 0;
		right: 0;
		font-weight: bold;
		transition: 500ms color;
		transition-delay: 0;
	}

	.hold-button .hold-label {
		color: transparent;
	}

	.hold-button.holding .hold-label,
	.hold-button.held .hold-label {
		color: var(--color-text-light);
		transition-delay: 300ms;
		transition-duration: 700ms;
	}

	.hold-button.held .hold-button-background,
	.hold-button.holding .hold-button-background {
		right: 0;
	}

	.hold-button-background {
		background-color: var(--color-main);
		position: absolute;
		height: 100%;
		width: 100%;
		right: 100%;
		bottom: 0;

		transition: right 800ms;
		transition-delay: 200ms;
	}
</style>
