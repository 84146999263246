import { openOverlay } from ".."
import ImageViewer from "../../comps/ImageViewer.svelte"
import type { OverlayOptions } from "../OverlayController"
import Confirm from "./Confirm.svelte"
import MultiButton from "./MultiButton.svelte"
import Popup from "./Popup.svelte"
import Text from "./Text.svelte"
import type {
	ConfirmInputOptions,
	MultiButtonOptions,
	ConfirmPromiseOptions,
	PopupInputOptions,
	TextInputOptions,
} from "./types"

export function openConfirmOverlay(
	options: ConfirmInputOptions,
	overlayConfig?: OverlayOptions
) {
	return openOverlay(Confirm, {
		classOverlay: "flex-column center-ver confirm-overlay",
		...overlayConfig,
		props: options,
	})
}

export function asyncConfirmOverlay(
	options: ConfirmPromiseOptions,
	overlayConfig?: OverlayOptions
) {
	return new Promise((res, rej) => {
		openConfirmOverlay(
			{
				prompt: options.prompt,
				invertWarning: false,
				buttons: {
					confirm: {
						text: options.confirmText,
						onClick: () => res(null),
					},
					deny: {
						text: options.denyText,
						onClick: () => rej(null),
					},
				},
			},
			{
				classHeading: "no-underline",
				...overlayConfig,
				heading: options.title,
				beforeClose: async () => rej(null),
			}
		)
	})
}

export function openMultiButtonOverlay(
	options: MultiButtonOptions,
	overlayConfig?: OverlayOptions
) {
	return openOverlay(MultiButton, {
		classOverlay: "flex-column center-ver confirm-overlay",
		...overlayConfig,
		props: options,
	})
}

export function initConfirmOverlay(
	options: ConfirmInputOptions,
	overlayConfig?: OverlayOptions
) {
	return (e: MouseEvent) => {
		openOverlay(Confirm, {
			classOverlay: "flex-column center-ver",
			...overlayConfig,
			props: options,
		})
	}
}

export function openPopupOverlay(
	options?: PopupInputOptions,
	overlayConfig?: OverlayOptions
) {
	openOverlay(Popup, {
		classOverlay: "flex-column center-ver",
		...overlayConfig,
		props: options,
	})
}

export function initPopupOverlay(
	options?: PopupInputOptions,
	overlayConfig?: OverlayOptions
) {
	return (e: MouseEvent) => {
		openOverlay(Popup, {
			classOverlay: "flex-column center-ver",
			...overlayConfig,
			props: options,
		})
	}
}

export function openTextOverlay(
	options: TextInputOptions,
	overlayConfig?: OverlayOptions
) {
	openOverlay(Text, { ...overlayConfig, props: options })
}

export function initTextOverlay(
	options: TextInputOptions,
	overlayConfig?: OverlayOptions
) {
	return (e: MouseEvent) => {
		openOverlay(Text, { ...overlayConfig, props: options })
	}
}

export function openImageOverlay(
	images: Array<string>,
	overlayConfig?: OverlayOptions
) {
	openOverlay(ImageViewer, {
		...overlayConfig,
		classOverlay: "image-overlay",
		props: { images },
	})
}
