<script lang="ts">
	import { Media, Scene, Folder, type LightshowContentSequence, LightshowBreak } from "luxedo-data"
	import NewLightshowSceneDropzone from "./NewLightshow_SceneDropzone.svelte"
	import { DragController, type DraggableContent } from "../../../../stores/DragContext"
	import { ShowLibraryController } from "../ShowLibraryController"
	import DesignTile from "../../../reusable/file-system/tiles/DesignTile.svelte"
	import { ArrowTailed } from "svelte-comps/icons"
	import NewLightshowBreak from "./NewLightshow_Break.svelte"
	import DownloadIcon from "../../../reusable/icons/DownloadIcon.svelte"
	import { Toast } from "svelte-comps/toaster"
	import { onMount } from "svelte"
	import LoadingSpinner from "../../../reusable/loaders/LoadingSpinner.svelte"

	let lightshowTitle = $state("Enter lightshow title...")
	let lightshowSequence: LightshowContentSequence = $state([])
	let isEditingLightshow: boolean = false

	let isDraggingOver = $state(false)
	let isLoading = $state(false)

	ShowLibraryController.LightshowEditor.subscribe((ctx) => {
		if (ctx.lightshowDraft) lightshowTitle = ctx.lightshowDraft.name
		lightshowSequence = ctx.sequence
		isEditingLightshow = !!ctx.lightshowDraft
		if (ctx.lightshowDraft) lightshowTitle = ctx.lightshowDraft.name
	})

	async function onShowNameChange(
		e: KeyboardEvent & {
			currentTarget: HTMLHeadingElement
		}
	) {
		e.stopImmediatePropagation()
		if (e.key === "Enter") {
			e.currentTarget.blur()
			await ShowLibraryController.LightshowEditor.save(lightshowTitle)
		}

		const target = e.currentTarget

		setTimeout(() => {
			lightshowTitle = target.textContent
		})
	}

	function addBreak() {
		ShowLibraryController.LightshowEditor.addBlock(new LightshowBreak(5))
	}

	function generateDropListener(index: number) {
		const onDrop = (scene: Scene | LightshowBreak) => {
			if (
				DragController.dragContent instanceof Media ||
				DragController.dragContent instanceof Folder
			)
				return

			if (DragController.dragLightshowDesignIndex !== undefined)
				ShowLibraryController.LightshowEditor.deleteBlock(DragController.dragLightshowDesignIndex)
			ShowLibraryController.LightshowEditor.addBlock(scene, index)
		}

		return onDrop
	}

	function generateBreakUpdater(positionIndex: number) {
		return (newTime: number) => {
			if (!(lightshowSequence[positionIndex] instanceof LightshowBreak)) {
				console.error({ positionIndex, checking: lightshowSequence[positionIndex] })
				throw new Error(
					"Trying to update the duration of a break, but the lightshow index does not align with a break. "
				)
			}
			ShowLibraryController.LightshowEditor.deleteBlock(positionIndex)
			ShowLibraryController.LightshowEditor.addBlock(new LightshowBreak(newTime))
		}
	}

	function onDropFirstScene(e: DragEvent, content: DraggableContent, positionIndex?: number) {
		if (!(content instanceof Scene)) return

		isDraggingOver = false

		ShowLibraryController.LightshowEditor.addBlock(content)
	}

	function onDragOver() {
		isDraggingOver = true
	}

	function onDragLeave() {
		isDraggingOver = false
	}

	function enforceLightshowName() {
		Toast.error("Don't forget to title your new lightshow!")

		const labelElem = document.getElementById(`new-show-title-input`)
		setTimeout(() => {
			const range = document.createRange()
			range.selectNodeContents(labelElem)
			const selection = window.getSelection()
			selection.removeAllRanges()
			selection.addRange(range)
		})
	}

	async function onSave() {
		if (lightshowTitle === "Enter lightshow title...") return enforceLightshowName()
		isLoading = true
		try {
			await ShowLibraryController.LightshowEditor.save(lightshowTitle)
			Toast.success(`Lightshow successfully ${isEditingLightshow ? "updated" : "created"}!`)
		} catch (e) {
			console.error("[ERROR] ", e)
			Toast.error("Unable to save lightshow at this time. Please try again.")
		}
		isLoading = false
	}
</script>

<div id="lightshow-editor">
	<div id="editor-header">
		<h3 id="new-show-title-input" onkeydown={onShowNameChange} contenteditable={true}>
			{lightshowTitle}
		</h3>
		<!-- <button id="add-break-button" class="small link-button" on:click={addBreak}>Add Break</button> -->
	</div>
	{#if lightshowSequence.length}
		<div id="selected-show-container">
			<div class="first-block">
				<NewLightshowSceneDropzone index={0} onDrop={generateDropListener(0)} />
			</div>
			{#each lightshowSequence as block, i}
				<div class="block-container">
					{#if block instanceof Scene}
						<div class="lightshow-block-preview">
							<DesignTile
								design={block}
								lightshowIndex={i}
								remove={() => ShowLibraryController.LightshowEditor.deleteBlock(i)}
							/>
							<ArrowTailed />
							<NewLightshowSceneDropzone index={i} onDrop={generateDropListener(i + 1)} />
						</div>
					{:else}
						<div class="lightshow-block-preview">
							<NewLightshowBreak
								breakObj={block}
								onBreakUpdate={generateBreakUpdater(i)}
								lightshowIndex={i}
								remove={() => ShowLibraryController.LightshowEditor.deleteBlock(i)}
							/>
							<ArrowTailed />
							<NewLightshowSceneDropzone index={i} onDrop={generateDropListener(i + 1)} />
						</div>
					{/if}
				</div>
			{/each}
		</div>
	{:else}
		<div
			id="empty-show-container"
			role="presentation"
			ondrop={DragController.onDrop(onDropFirstScene)}
			ondragleave={onDragLeave}
			ondragover={DragController.onDragOver(onDragOver)}
			ondragend={DragController.onDragEnd(onDragLeave)}
		>
			{#if !isDraggingOver}
				<DownloadIcon />

				<p>
					Lightshows are rendered playlists of scenes you can schedule, allowing you to create a
					seamless masterpiece from your individual scenes. <br /> Select or drag the scenes below to
					begin creating your lightshow.
				</p>
			{/if}
		</div>
	{/if}
	<div class="button-container">
		<button class="link-button" onclick={() => ShowLibraryController.LightshowEditor.closeEditor()}
			>Cancel</button
		>
		{#if isLoading}
			<LoadingSpinner height="2rem" color="var(--color-main)" />
		{:else}
			<button
				class="outline-button"
				onclick={onSave}
				disabled={lightshowSequence.filter((show) => (show instanceof Scene ? true : false))
					.length === 0}>Save Lightshow</button
			>
		{/if}
	</div>
</div>

<style>
	#lightshow-editor {
		height: fit-content;
		margin-bottom: 1rem;
		padding: 1rem;
		border: 1px solid var(--color-border);
		background-color: var(--color-bg);
		border-radius: var(--br);
		box-shadow: var(--shadow-small);
	}

	#lightshow-editor :global(.lightshow-block) {
		position: relative;
	}

	#editor-header {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: flex-end;
	}

	#new-show-title-input {
		margin: 0;
		font-size: var(--h1);
		color: var(--color-text-light);
	}

	.lightshow-block-preview {
		display: flex;
		flex-direction: row;
		align-items: center;
	}

	.lightshow-block-preview :global(.lightshow-block) {
		margin: 0 0.5rem;
	}

	.block-container {
		position: relative;
	}

	.button-container {
		/* width: fit-content; */
		justify-content: flex-end;
		margin: 0;
	}

	.first-block {
		height: calc(var(--tile-width) + 1rem);
		position: relative;
	}

	#selected-show-container {
		display: flex;
		flex-direction: row;
		justify-content: flex-start;
		padding: 1rem 0 0.5rem 0;
		min-height: calc(var(--tile-width) + 4rem);
		overflow-x: auto;
		margin-bottom: 0.5rem;
	}

	#empty-show-container {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		min-height: calc(var(--tile-width) + 4.5rem);
	}

	#empty-show-container p {
		color: var(--color-text);
		margin-top: 1rem;
		text-align: center;
	}
</style>
