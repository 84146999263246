<script lang="ts">
	import type { Lightshow, Folder, Media, Scene } from "luxedo-data"
	import { Toast } from "../../toaster"
	import type { MaskBase } from "luxedo-canvas/asset"
	import { onMount } from "svelte"

	interface Props {
		file: Scene | Media | Folder | Lightshow | MaskBase
		onRename: (newName: string) => void
		triggerRename: () => void
	}

	let { file, onRename, triggerRename = $bindable() }: Props = $props()

	let labelElem: HTMLSpanElement = $state()

	triggerRename = () => {
		if (!onRename) return
		labelElem.contentEditable = "true"

		setTimeout(() => {
			const range = document.createRange()
			range.selectNodeContents(labelElem)
			const selection = window.getSelection()
			selection.removeAllRanges()
			selection.addRange(range)
		})
	}

	function checkIfRename(e: FocusEvent & { currentTarget: HTMLSpanElement }) {
		const value = e.currentTarget.innerText
		if (value === "") {
			labelElem.innerText = file.name
			return Toast.error("Name cannot be empty!")
		}

		if (value !== file.name) {
			onRename(value)
		}

		labelElem.contentEditable = "false"
	}

	function onKeypress(
		e: KeyboardEvent & {
			currentTarget: HTMLSpanElement
		}
	) {
		e.stopPropagation()
		e.stopImmediatePropagation()

		if (e.key === "Escape") {
			e.currentTarget.innerText = file.name
			e.currentTarget.blur()
		}
		if (e.key === "Enter") {
			e.preventDefault()
			e.currentTarget.blur()
		}
	}

	function initializeRename(
		e: MouseEvent & {
			currentTarget: EventTarget & HTMLSpanElement
		}
	) {
		e.stopImmediatePropagation()
	}
</script>

<span
	tabindex="0"
	id="tile-label-{file.constructor.name}-{file.id}"
	role="textbox"
	class="tile-label"
	bind:this={labelElem}
	onclick={(e) => e.stopImmediatePropagation()}
	onkeydown={onKeypress}
	onblur={checkIfRename}
	ondblclick={initializeRename}
	title={file.name}
>
	<!-- {#if "tags" in file && file.tags.length}
		<dv
			class="tag-container"
			style="min-width: calc(0.5rem + {(file.tags.length - 1) * 0.25}rem)"
			contenteditable="false"
		>
			{#each file.tags as tag, i}
				<div
					class="tag-indicator"
					style="background-color: {DataHandlerTag.get(tag)?.color}; right: {0.25 * i}rem;"
				/>
			{/each}
		</dv>
	{/if} -->
	{file.name}
</span>

<span></span>

<style>
	.tile-label {
		max-width: var(--tile-width);

		display: flex;
		flex-direction: row;
		align-items: center;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		width: 100%;
		max-width: var(--tile-width);
	}

	/* 
	.tag-container {
		position: relative;
		display: flex;
		flex-direction: row;
		margin-right: 0.5rem;
	} */

	/* .tag-indicator {
		position: absolute;
		top: 0;
		bottom: 0;
		margin-top: auto;
		margin-bottom: auto;
		width: 0.5rem;
		height: 0.5rem;
		border-radius: 999px;
		box-shadow: var(--shadow-large);
	} */
</style>
