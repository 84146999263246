<script lang="ts">
	import { TextRowSelector } from "svelte-comps/inputs"
	import {
		ShowLibraryController,
		type ShowLibraryViewOption,
	} from "../ShowLibraryController"
	import { DataHandlerScene, Device } from "luxedo-data"
	import ProjectTags from "../../../reusable/tags/ProjectTags.svelte"
	import SearchBarInput from "../../../reusable/input/SearchBarInput.svelte"
	import ShowLibraryFilter from "./ShowLibraryFilter.svelte"
	import { onMount } from "svelte"
	import { openNewSceneOverlay } from "../../../reusable/overlays"

	interface Props {
		selectedTags?: Array<number>
		searchInput?: string
	}

	let {
		selectedTags = $bindable([]),
		searchInput = $bindable(undefined),
	}: Props = $props()

	let viewType: ShowLibraryViewOption = $state("Scenes")
	let isEditingLightshow: boolean = $state(false)

	let isSearchOpen: boolean = $state(false)
	let userHasNoScenes: boolean = $state(false)

	let selectedDevice: Device

	ShowLibraryController.subscribe((ctx) => {
		isEditingLightshow = ctx.isEditingLightshow
		selectedDevice = ctx.deviceFilter
	})

	function onSearch(searchValue: string) {
		searchInput = searchValue
	}

	function onCancelSearch() {
		searchInput = undefined
	}

	function createScene() {
		openNewSceneOverlay({ device: selectedDevice })
	}

	onMount(() => {
		if (DataHandlerScene.getMany().length === 0) userHasNoScenes = true
	})

	$effect(() => {
		ShowLibraryController.changeView(viewType)
	})
</script>

<div id="library-options">
	{#if isEditingLightshow}
		<h3>Select Scenes</h3>
	{:else if userHasNoScenes}
		<h3>Scenes</h3>
		<button
			class="create-button outline-button"
			onclick={() =>
				viewType === "Scenes"
					? createScene()
					: ShowLibraryController.LightshowEditor.createLightshow()}
			title="Create New Scene"
		>
			Create Scene
		</button>
	{:else}
		<TextRowSelector
			options={["Scenes", "Lightshows"]}
			bind:selectedOption={viewType}
			fontSize="var(--h1)"
		/>
		<button
			class="create-button outline-button"
			onclick={() =>
				viewType === "Scenes"
					? createScene()
					: ShowLibraryController.LightshowEditor.createLightshow()}
			title="Create New {viewType.substring(0, viewType.length - 1)}"
		>
			Create {viewType.substring(0, viewType.length - 1)}
		</button>
	{/if}
	<div id="library-filter-actions">
		<ProjectTags bind:selectedTags isLibraryContext={true} />
		<ShowLibraryFilter />
		<SearchBarInput
			{onSearch}
			onClose={onCancelSearch}
			bind:isOpen={isSearchOpen}
		/>
	</div>
</div>

<style>
	h3 {
		font-size: var(--h1);
		margin: 0;
		color: var(--color-text-light);
	}

	#library-options {
		display: flex;
		flex-direction: row;
		padding: 1rem;
		background-color: var(--color-bg-dark);
		border-radius: var(--br);
		align-items: center;
		white-space: nowrap;

		/* overflow: hidden; */
		position: sticky;
		top: 0;
		z-index: 10;
		box-shadow: var(--shadow-small);
	}

	.create-button {
		margin-left: 1rem;
		padding: 0rem 1rem;
	}

	#library-filter-actions {
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: flex-end;
		flex-grow: 1;
		overflow: hidden;
	}
</style>
