<script lang="ts">
	import LightshowThumbnail from "../../../file-system/tiles/LightshowThumbnail.svelte"
	import {
		DataHandlerLightshow,
		DataHandlerScene,
		Device,
		Lightshow,
		Scene,
		type FullCalendarEvent,
	} from "luxedo-data"
	import { ShowOverviewController } from "../ShowOverviewController"
	import { Toast } from "svelte-comps/toaster"
	import { DateTime } from "luxon"
	import ProjectTags from "../../../tags/ProjectTags.svelte"
	import { triggerContextMenuWithGenerator } from "svelte-comps/context-menu"
	import { CTXOptions } from "../../../../../modules/ctx-menu/ContextMenuOptions"
	import { ArrowDown, SettingsIcon } from "svelte-comps/icons"
	import { ShowLibraryController } from "../../../../routes/show-library/ShowLibraryController"
	import PreviewInBrowserIcon from "../../../icons/PreviewInBrowserIcon.svelte"
	import { ScheduleController } from "../../../../routes/schedule/ScheduleController"
	import LightshowScheduleIcon from "../../../icons/LightshowScheduleIcon.svelte"
	import ShowOverviewHeaderPlayOnProjector from "./ShowOverview_PlayOnProjector.svelte"
	import ShowOverviewVideoPlayer from "./ShowOverview_VideoPlayer.svelte"
	import ProjectorIcon from "../../../icons/ProjectorIcon.svelte"
	import ShowOverviewAssignDevice from "./ShowOverview_AssignDevice.svelte"

	let show: Scene | Lightshow = $state()
	let event: FullCalendarEvent
	let device: Device = $state()

	ShowOverviewController.subscribe((ctx) => {
		show = ctx.show
		event = ctx.event
		device = ctx.device
	})

	function onSceneNameChange(
		e: KeyboardEvent & {
			currentTarget: EventTarget & HTMLHeadingElement
		}
	) {
		if (e.key === "Enter") e.currentTarget.blur()
	}

	async function saveSceneName(
		e: FocusEvent & {
			currentTarget: EventTarget & HTMLHeadingElement
		}
	) {
		const newName = e.currentTarget.textContent
		if (newName === show?.name) return

		try {
			if (show instanceof Scene) {
				await DataHandlerScene.rename(show, newName)
			} else {
				await DataHandlerLightshow.rename(show, newName)
			}
			Toast.success("Show title updated successfully! ")
		} catch (e) {
			console.error("[ERROR] While renaming scene", e)
			Toast.error(
				"An error occurred while updating the title of your show. Please refresh and try again. "
			)
		}
	}

	function initSchedule() {
		ScheduleController.EventEditor.editEvent(event, {
			show,
		})
	}

	function handleEdit() {
		ShowLibraryController.editShow(show)
	}
</script>

<div class="heading-container">
	<div class="internal-heading-container">
		<div class="tile">
			{#if show instanceof Lightshow}
				<LightshowThumbnail lightshow={show} />
			{:else}
				<img src={show?.src?.thumbnail} alt="{show?.name} Thumbnail" />
			{/if}
		</div>
		<div class="heading">
			<div class="lightshow-info">
				<div class="left">
					<h3
						id="show-preview-title"
						spellcheck="false"
						onkeydown={onSceneNameChange}
						onblur={saveSceneName}
						contenteditable={true}
					>
						{show?.name === "__temp_ls__"
							? `Temporary playlist for ${DateTime.fromJSDate(show?.updated_at).toFormat(
									"LLL dd, yyyy"
								)}`
							: (show?.name ?? "")}
					</h3>
					<p>{show?.description ?? ""}</p>
				</div>
			</div>
			<div class="tag-container">
				<ProjectTags
					alignLeft
					selectedTags={show?.tags}
					onToggle={(tag) => show?.toggleTag(tag)}
					isLibraryContext={false}
				/>
			</div>
		</div>
		<div class="right">
			<div class="button-container lightshow-actions">
				<button
					id="more-options-button"
					class="icon"
					title="More Options"
					onclick={triggerContextMenuWithGenerator(
						show instanceof Scene
							? CTXOptions.Scene.createOptions
							: CTXOptions.Lightshow.createOptions,
						[show, { fromOverlay: true, triggerReupload: ShowOverviewController.showReuploadForm }]
					)}><ArrowDown /></button
				>

				{#if !show?.isDirectUpload}
					<button id="edit-lightshow-btn" class="icon" title="Edit Show" onclick={handleEdit}
						><SettingsIcon /></button
					>
				{/if}
				<button
					id="preview-lightshow-btn"
					class="icon"
					title="View Scene in Browser"
					onclick={ShowOverviewController.showVideoPlayer}><PreviewInBrowserIcon /></button
				>
				{#if device}
					<button
						id="schedule-lightshow-btn"
						class="icon"
						title="Schedule Show"
						onclick={initSchedule}><LightshowScheduleIcon /></button
					>
					<ShowOverviewHeaderPlayOnProjector {show} {device} />
				{/if}
			</div>
			{#if device && "color" in device}
				<div class="color-block" style="background-color: {device.color};"></div>
			{/if}
			<div class="flex-row">
				<div class="flex-column">
					{#if device}
						<p class="duration-info">Projector:</p>
						<div>{device?.name}</div>
					{:else}
						<ShowOverviewAssignDevice />
					{/if}
				</div>

				{#if device}
					<ProjectorIcon {device} />
				{/if}
			</div>
		</div>
	</div>
	<!-- Only exist when expanded -->

	<ShowOverviewVideoPlayer />
</div>

<style>
	.heading-container {
		background-color: var(--color-bg-dark);
		backdrop-filter: blur(3rem);
		padding: 2rem;
		--tile-width: 12rem;
	}

	.internal-heading-container {
		display: flex;
		flex-direction: row;
		transition: transform;
		transition-duration: 500ms;
		transition-delay: 250ms;
	}

	.heading {
		display: flex;
		flex-direction: column;
		width: 50%;
		justify-content: flex-end;
	}

	.tile {
		min-width: var(--tile-width);
		width: var(--tile-width);
		height: var(--tile-width);
	}

	.lightshow-info {
		height: fit-content;
		margin-left: 1rem;
		overflow: hidden;
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: flex-end;
	}

	/* 
	.right {
		position: absolute;
		right: 4rem;
		top: calc(var(--tile-width) - 1.5rem);
		width: fit-content;
	} */

	.left {
		width: 100%;
	}

	.right {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		align-items: flex-end;

		padding-top: 3rem;
		margin-bottom: 1rem;
		width: 100%;
	}

	.right .flex-row div {
		width: fit-content;
	}

	.right .flex-row :global(svg) {
		margin-left: 1rem;
		align-self: flex-end;
	}

	.color-block {
		width: 3rem;
		height: 3rem;
		right: 2.5rem;
		top: 11rem;
		position: absolute;
		border-radius: 100%;
		z-index: -1;
		filter: blur(3rem);
	}

	.duration-info {
		color: var(--color-text);
		margin-bottom: 0;
	}

	.tag-container {
		margin: 1rem 0 0 1rem;
		padding-right: 1rem;
	}

	.lightshow-actions {
		justify-content: flex-end;
	}

	#preview-lightshow-btn :global(.svg-fill) {
		fill: var(--color-main);
	}

	#preview-lightshow-btn :global(.svg-stroke) {
		stroke: var(--color-main);
	}

	#preview-lightshow-btn,
	#schedule-lightshow-btn {
		margin-right: 1rem;
		transition: transform 250ms ease-out;
	}

	#more-options-button {
		margin-right: 0.25rem;
	}

	#more-options-button :global(.svg-stroke) {
		stroke: var(--color-text);
	}

	#edit-lightshow-btn,
	#preview-lightshow-btn {
		margin-right: 0.5rem;
	}

	#more-options-button:hover,
	#more-options-button:focus-visible,
	#edit-lightshow-btn:hover,
	#edit-lightshow-btn:focus-visible,
	#preview-lightshow-btn:hover,
	#preview-lightshow-btn:focus-visible,
	#schedule-lightshow-btn:hover,
	#schedule-lightshow-btn:focus-visible {
		transform: scale(1.5);
	}

	#edit-lightshow-btn :global(svg) {
		width: 1rem;
	}

	#more-options-button :global(svg) {
		height: auto;
		width: 1.5rem;
	}

	#edit-lightshow-btn :global(.svg-stroke) {
		stroke: var(--color-text-light);
	}

	#edit-lightshow-btn :global(.svg-fill) {
		fill: var(--color-text-light);
	}

	.lightshow-info h3 {
		width: 100%;
		font-size: 3rem;
		margin: 0;
		overflow: hidden;
		text-overflow: ellipsis;
		display: -webkit-box;
		-webkit-line-clamp: 2;
		-webkit-box-orient: vertical;
	}
</style>
