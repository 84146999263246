<script lang="ts">
	import { run, createBubbler, preventDefault, handlers } from "svelte/legacy"

	const bubble = createBubbler()
	import type { DeviceGroupSlot } from "luxedo-data"
	import GroupCanvasSlotInstance from "./GroupCanvas_SlotInstance.svelte"
	import { GroupCanvasController } from "./GroupCanvasController"
	import { GroupEditorController } from "../GroupEditorController"
	import { GroupOverlapController } from "../steps/blend/GroupOverlapController"
	import GroupCanvasOverlapInstance from "./GroupCanvas_OverlapInstance.svelte"
	import ExpandIcon from "../../../icons/ExpandIcon.svelte"

	interface Props {
		slots: Array<DeviceGroupSlot>
		slotOverlaps?: Array<GroupOverlapController.OverlapInstance>
		onSlotClick?: (slotID: number) => void
		onOverlapClick?: (overlapID: string) => void
	}

	let {
		slots,
		slotOverlaps = [],
		onSlotClick = undefined,
		onOverlapClick = undefined,
	}: Props = $props()

	let canvasElem: HTMLDivElement = $state()
	let zoomLevel: number = $state()
	let isExpanded: boolean = $state()
	let resolution: string = $state()

	GroupCanvasController.subscribe((ctx) => {
		zoomLevel = ctx.zoomLevel
		isExpanded = ctx.isExpanded

		resolution = ctx.groupSize.join("x")
	})

	function handleScroll(
		e: WheelEvent & {
			currentTarget: EventTarget & HTMLDivElement
		}
	) {
		if (e.deltaY < 0) {
			GroupCanvasController.zoomIn()
		} else if (e.deltaY > 0) {
			GroupCanvasController.zoomOut()
		}
	}

	function handleMouseDown(
		e: MouseEvent & {
			currentTarget: HTMLDivElement
		}
	) {
		if (e.button !== 2) return
		e.preventDefault()

		const startPos = {
			x: e.pageX,
			y: e.pageY,
			left: e.currentTarget.scrollLeft,
			top: e.currentTarget.scrollTop,
		}

		const mouseUpHandler = () => {
			document.removeEventListener("mousemove", mouseMoveHandler)
			document.removeEventListener("mouseup", mouseUpHandler)
		}

		const mouseMoveHandler = (e: MouseEvent) => {
			const diffX = e.pageX - startPos.x
			const diffY = e.pageY - startPos.y

			canvasElem.scrollTo(startPos.left - diffX, startPos.top - diffY)
		}

		document.addEventListener("mousemove", mouseMoveHandler)
		document.addEventListener("mouseup", mouseUpHandler)
	}

	function handleKeyEvents(e: KeyboardEvent) {
		if (e.key === "Escape") {
			GroupEditorController.Slots.deselectSlot()
			GroupOverlapController.selectOverlap(undefined)
			e.stopPropagation()
		}
	}

	run(() => {
		GroupCanvasController.setCanvasElement(canvasElem)
	})
</script>

<div
	id="device-group-canvas"
	bind:this={canvasElem}
	oncontextmenu={handlers(
		preventDefault(bubble("contextmenu")),
		preventDefault(bubble("contextmenu"))
	)}
	onmousedown={handleMouseDown}
	onwheel={preventDefault(handleScroll)}
>
	{#if slots}
		<div id="editor-canvas" style="transform: scale({zoomLevel});">
			{#each slots as slot (slot.id)}
				{#if !slot.deleted}
					<GroupCanvasSlotInstance
						{slot}
						onClick={onSlotClick ? () => onSlotClick(slot.id) : null}
					/>
				{/if}
			{/each}
			{#if slotOverlaps}
				{#each slotOverlaps as overlap (overlap.id)}
					<GroupCanvasOverlapInstance
						onClick={() => onOverlapClick(overlap.id)}
						overlapInstance={overlap}
					/>
				{/each}
			{/if}
		</div>
		{#if resolution}
			<span id="resolution-indicator">{resolution}</span>
		{/if}
	{/if}
</div>
<button
	id="expand-canvas-button"
	class="small icon"
	title={isExpanded ? "Shrink Canvas" : "Expand Canvas"}
	onclick={() => GroupCanvasController.toggleCanvasExpand()}
>
	<ExpandIcon />
</button>
<svelte:document onkeydown={handleKeyEvents} />

<style>
	#device-group-canvas {
		width: 100%;
		height: 100%;
		border: 1px dashed var(--color-border);
		background-color: var(--color-bg-dark);
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		overflow: scroll;
		padding: 1rem;
		position: relative;

		transition: width 500ms;
	}

	#device-group-canvas::-webkit-scrollbar {
		display: none;
	}

	#editor-canvas {
		transform-origin: 0% 0% 0px;
		min-height: 100%;
		min-width: 100%;
		transition: transform 0.5s;
	}

	#expand-canvas-button {
		position: absolute;
		top: 1.5rem;
		right: 4rem;
	}

	#resolution-indicator {
		position: absolute;
		color: var(--color-text);
		bottom: 0rem;
		right: 0.5rem;
	}

	#expand-canvas-button :global(.svg-stroke) {
		stroke: var(--color-text-light);
	}

	#expand-canvas-button:hover :global(.svg-stroke),
	#expand-canvas-button:focus-visible :global(.svg-stroke) {
		stroke: var(--color-main);
	}
</style>
