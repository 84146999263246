<script lang="ts">
	import { run } from 'svelte/legacy';

	import { DataHandlerScene, Device, Lightshow, Scene, type FullCalendarEvent } from "luxedo-data"
	import DirectUploadForm from "../../new-scene/direct-upload/DirectUploadForm.svelte"
	import { ShowOverviewController } from "../ShowOverviewController"
	import { Toast } from "svelte-comps/toaster"

	import ShowInfoLightshow from "./ShowInfo_Lightshow.svelte"
	import ShowInfoScene from "./ShowInfo_Scene.svelte"
	import ShowInfoEvent from "./ShowInfo_Event.svelte"
	import WarningIcon from "../../../icons/WarningIcon.svelte"
	import { ShowLibraryController } from "../../../../routes/show-library/ShowLibraryController"
	import { ScheduleController } from "../../../../routes/schedule/ScheduleController"
	import { DateTime } from "luxon"
	import { SuperscaleProgressManager } from "../../../../../modules/progress-reporting/ProgressContext_Superscale"
	import ShowInfoSuperscale from "./ShowInfo_Superscale.svelte"

	let show: Scene | Lightshow = $state()
	let event: FullCalendarEvent = $state()
	let device: Device = $state()

	let newDeviceId: number = $state()

	let isPreviewing: boolean = $state()
	let isReuploading: boolean = $state()

	let isUnderscaled: boolean = $state()

	ShowOverviewController.subscribe((ctx) => {
		show = ctx.show
		event = ctx.event
		device = ctx.device
		newDeviceId = ctx.newDeviceId
		isPreviewing = ctx.isPreviewing
		isReuploading = ctx.isReuploading

		if (show instanceof Scene) isUnderscaled = show.isUnderscaled
		else isUnderscaled = false
	})

	type EventDetails = {
		start: string
		end: string
	}

	let eventDetails: EventDetails = $state()

	let triggerUpload: () => void = $state()
	let onUploadCancel: () => void = $state()

	function triggerReupload() {
		isReuploading = true
		ShowOverviewController.hideVideoPlayer()
	}

	async function onReuploadComplete() {
		Toast.success("Scene successfully updated!")
		isReuploading = false
		if (show instanceof Scene) DataHandlerScene.pull([show.id])
	}

	async function handleSave() {
		try {
			const amt = await ShowOverviewController.saveChanges()
			if (amt == "many") Toast.success("All unassigned scenes and lightshows updated successfully!")
			else Toast.success("Show updated successfully!")
		} catch (e) {
			console.error("ERROR updating selected device.", e)
			Toast.error("Error updating selected device, please try again.")
		}
	}

	function handleEdit() {
		if (show instanceof Scene) {
			ShowLibraryController.editShow(show)
		} else {
			ShowLibraryController.LightshowEditor.editLightshow(show)
		}
	}

	async function deleteEvent() {
		try {
			await device.timetableManager.deleteEvent(device.timetableManager.getEvent(event))
			await ScheduleController.Calendar.refreshEvents()

			Toast.success("Event successfully removed from schedule!")
			ShowOverviewController.close()
		} catch (e) {
			console.error("Error removing event from schedule", e)
			Toast.error("Unable to remove from schedule, please refresh and try again...")
		}
	}

	function editEvent() {
		ScheduleController.EventEditor.editEvent(event)
		ShowOverviewController.close()
	}

	function initSchedule() {
		ScheduleController.EventEditor.editEvent(event, {
			show,
		})
	}

	function updateEventDetails() {
		if (!event) return

		const start = DateTime.fromJSDate(event.start).toFormat("ff")
		const end = DateTime.fromJSDate(event.end).toFormat("ff")

		eventDetails = {
			start,
			end,
		}
	}

	run(() => {
		event && updateEventDetails()
	});
</script>

{#if isReuploading && show instanceof Scene}
	<DirectUploadForm
		scene={show}
		bind:triggerUpload
		bind:triggerCancel={onUploadCancel}
		onComplete={onReuploadComplete}
	/>
{:else}
	<div class="show-information">
		{#if show instanceof Lightshow}
			<ShowInfoLightshow lightshow={show} />
		{:else}
			<ShowInfoScene scene={show} />
		{/if}
		{#if event}
			<ShowInfoEvent {eventDetails} />
		{/if}
		{#if isUnderscaled}
			<ShowInfoSuperscale />
		{/if}
		{#if !device}
			<div id="no-device-warning">
				<WarningIcon strokeColor="var(--color-error)" />
				<span>
					The device associated with this scene is no longer registered under your account, please
					assign to a new projector above.
				</span>
			</div>
		{/if}
	</div>
{/if}

{#if !isPreviewing && device}
	<div class="overlay-button-container">
		{#if isReuploading}
			<button
				class="link-button"
				onclick={() => {
					onUploadCancel()
					isReuploading = false
				}}
			>
				Cancel
			</button>
		{:else}
			<button class="link-button" onclick={() => ShowOverviewController.close()}> Close </button>
		{/if}

		{#if event}
			<button id="remove-schedule-button" class="error" onclick={() => deleteEvent()}>
				Cancel Event
			</button>

			<button id="edit-schedule-button" class="alt" onclick={() => editEvent()}>
				Edit Schedule
			</button>
		{:else if !isReuploading}
			<button id="edit-schedule-button" class="alt" onclick={() => initSchedule()}>
				Schedule Show
			</button>
		{/if}
		{#if show?.isDirectUpload && isReuploading}
			<button onclick={() => (isReuploading ? triggerUpload() : triggerReupload())}>
				Reupload
				{#if !isReuploading}
					Scene
				{/if}
			</button>
		{/if}
		{#if !show?.isDirectUpload}
			<button onclick={handleEdit}>
				Edit {show instanceof Scene ? "Scene" : "Lightshow"}
			</button>
		{/if}
	</div>
{:else if !device}
	<div class="overlay-button-container">
		<button class="link-button" onclick={() => ShowOverviewController.close()}> Close </button>

		<button onclick={handleSave} disabled={!newDeviceId}> Save </button>
	</div>
{/if}

<style>
	.show-information {
		overflow-y: scroll;
		display: grid;
		grid-template-columns: 70% 30%;
		/* grid-auto-columns: 70% 30%; */
	}

	.show-information > :global(*) {
		flex-basis: 50%;
	}

	#no-device-warning {
		color: var(--color-error);
		position: absolute;
		bottom: 1rem;
		left: 2rem;
		width: 60%;
		display: flex;
		align-items: center;
	}

	#no-device-warning span {
		margin-left: 1rem;
		line-height: 1rem;
	}

	#remove-schedule-button,
	#edit-schedule-button {
		margin-right: 0.5rem;
	}

	.show-information {
		padding: 1rem 2rem;

		color: var(--color-text-light);
	}

	.show-information :global(h3) {
		font-size: var(--h1);
		margin: 0;
	}

	.show-information :global(.details) {
		margin-left: 1rem;
	}

	.show-information :global(.details .label) {
		color: var(--color-text);
	}

	.overlay-button-container {
		font-size: var(--h2);
		position: absolute;
		bottom: 1rem;
		right: 1rem;
	}

	#no-device-warning {
		color: var(--color-error);
		position: absolute;
		bottom: 1rem;
		left: 2rem;
		width: 60%;
		display: flex;
		align-items: center;
	}

	#no-device-warning span {
		margin-left: 1rem;
		line-height: 1rem;
	}
</style>
