<script lang="ts">
	import type Dropzone from "dropzone"
	import {
		DataHandlerDevice,
		DataHandlerScene,
		DataHandlerSnapshot,
		type Scene,
	} from "luxedo-data"
	import { Toast } from "svelte-comps/toaster"
	import { ProgressBar } from "svelte-comps/progress-bar"
	import { onMount } from "svelte"
	import { DirectUploadProgressContext } from "../../../../../modules/progress-reporting/ProgressContext_DirectUpload"
	import { DropzoneInput } from "svelte-comps/inputs"
	import { RPCError } from "../../../../../../../../packages/rpc-client"
	import { getCurrentUser } from "../../../../../stores/UserStore"

	interface UploadFile extends Dropzone.DropzoneFile {
		hash: string
	}

	let processingProgress = $state(0)

	// Error handling
	let errorMessage: string = $state()
	let errorCode: number = $state()

	let isProcessing = $state(false)
	let activeUpload: UploadFile
	let dropzone: Dropzone = $state()

	let ignoreDefaultError = false

	export const triggerCancel = () => {
		dropzone.removeAllFiles()
		if (!activeUpload) return
		activeUpload = undefined
	}

	interface Props {
		// If reuploading for a scene
		scene?: Scene
		// If new direct upload
		deepFry?: "off" | "low" | "med" | "high"
		sceneName?: string
		projectFolder?: number
		selectedDeviceId?: number
		selectedSnapshotId?: number
		currentState?: "upload" | "process" | "error" | "complete"
		newSceneId?: number
		// These methods are to be bound by the parent component to trigger actions
		triggerUpload?: () => void
		onComplete: () => void
	}

	let {
		scene = undefined,
		deepFry = "off",
		sceneName = undefined,
		projectFolder = undefined,
		selectedDeviceId = undefined,
		selectedSnapshotId = undefined,
		currentState = $bindable("upload"),
		newSceneId = $bindable(undefined),
		triggerUpload = $bindable(undefined),
		onComplete,
	}: Props = $props()

	/** Called when the dropzone form is submitted. Ensures a scene entry exists and begins dropzone's submission process */
	async function onUpload(file: UploadFile, name: string, dz: Dropzone) {
		if (!scene) await createFileRecord(file)
		dz.processFile(file)
		activeUpload = file
	}

	/** Creates a database record for the new scene before uploading the actual file */
	async function createFileRecord(uploadFile: UploadFile) {
		try {
			let device = await DataHandlerDevice.get(selectedDeviceId)
			let snapshot = DataHandlerSnapshot.get(selectedSnapshotId)
			if (!snapshot) snapshot = await device.getSnapshot()
			const entryId = await DataHandlerScene.createEntry({
				name: sceneName,
				parent_id: projectFolder,
				target_device_id: selectedDeviceId,
				res_x: device.resX ?? snapshot.resolution.w,
				res_y: device.resY ?? snapshot.resolution.h,
				direct_upload: true,
			})

			newSceneId = entryId
		} catch (e) {
			if (e instanceof RPCError && e.statusCode === 507) {
				Toast.error(
					"Not enough storage remaining on your account... Try deleting content or upgrading storage."
				)
				ignoreDefaultError = true
			}
		}
	}

	/** Called by Dropzone when the file data is being sent to the server. */
	async function onDropzoneSend(
		file: UploadFile,
		xhr: XMLHttpRequest,
		formData: FormData
	) {
		formData.append("project_id", String(scene ? scene.id : newSceneId))
	}

	/** Called when there is an error (usually user error) due to the provided file (handled by dropzone). */
	async function onFail(
		file: UploadFile,
		message: string | Error,
		xhr: XMLHttpRequest
	) {
		if (!ignoreDefaultError) Toast.error(`Unable to upload scene. ${message}`)
		if (!scene && newSceneId) {
			const newScene = DataHandlerScene.get(newSceneId)
			if (newScene) await DataHandlerScene.deleteEntry(newScene)
			newSceneId = undefined
		}
	}

	/** Called when the file is successfully uploaded, begins the direct upload processing step. */
	async function onSuccess(file: UploadFile) {
		isProcessing = true
		currentState = "process"
		ignoreDefaultError = false

		const sceneId = scene ? scene.id : newSceneId

		await applyEffect(sceneId, deepFry)
		await DirectUploadProgressContext.processUpload(sceneId)

		const unsubscribe = (id) => {
			DirectUploadProgressContext.unsubscribe(sceneId, id)
		}

		const unsubscribeId = DirectUploadProgressContext.subscribe(sceneId, {
			progress: (prog) => {
				processingProgress = prog
			},
			fail: async (code, message) => {
				errorCode = code
				errorMessage = message
				currentState = "error"
				await onFail(undefined, errorMessage, undefined)
				unsubscribe(unsubscribeId)
			},
			success: () => {
				processingProgress = 1
				onComplete()
				unsubscribe(unsubscribeId)
				currentState = "complete"
			},
		})
	}

	async function applyEffect(sceneId, deepFry: "off" | "low" | "med" | "high") {
		await DataHandlerScene.pull([sceneId])
		const project = DataHandlerScene.get(sceneId)

		if (
			deepFry ===
			(project.sceneFilters.getEffectArguments("deep_fry")["brightness"] ??
				"off")
		)
			return
		if (deepFry === "off") await project.sceneFilters.removeFilter("deep_fry")
		else
			await project.sceneFilters.addFilter("deep_fry", `brightness=${deepFry}`)
	}

	let resetDropzone: () => void = $state()

	function reset() {
		newSceneId = undefined
		activeUpload = undefined
		isProcessing = false
		currentState = "upload"
		errorMessage = undefined
		errorCode = undefined
		resetDropzone()
	}

	onMount(reset)
</script>

<div id="direct-upload-form-container">
	{#if errorMessage}
		<div class="error-container">
			<p>{errorMessage} [Error Code: {errorCode}]</p>
			<button class="outline-button" onclick={reset}
				>Back to File Selection</button
			>
		</div>
	{:else if isProcessing}
		<div class="progress-container">
			<p>
				Your scene is currently being processed to create thumbnails and ensure
				the correct resolution.
			</p>
			<ProgressBar percentage={processingProgress} width={"100%"} />
		</div>
	{/if}

	<div
		id="dropzone-container"
		class="dropzone-container"
		style={!isProcessing && !errorMessage ? "" : "display: none;"}
	>
		<!-- uploadUrl={`${import.meta.env.VITE_API_URL}/workspace/project/splitupload`} -->
		<DropzoneInput
			removeFilesizeRestriction={getCurrentUser().hasPriv("commercial")}
			acceptedFiles={[".mp4", ".mov"]}
			placeholder="Drag video file here or click to select file for upload."
			hideButton
			uploadButtonId="scene-upload-button"
			uploadUrl={`/workspace/project/splitupload`}
			bind:triggerUpload
			bind:reset={resetDropzone}
			bind:dropzone
			{onUpload}
			{onDropzoneSend}
			{onFail}
			{onSuccess}
		/>
	</div>
</div>

<style>
	#direct-upload-form-container {
		height: 100%;
	}

	#direct-upload-form-container div#dropzone-container.dropzone-container {
		padding: 0;
		height: 100%;
	}

	#direct-upload-form-container
		div#dropzone-container.dropzone-container
		:global(form) {
		padding: 1rem 0 0 0;
	}

	.progress-container p {
		margin-bottom: 1.5rem;
		line-height: 1.25em;
		color: var(--color-text);
	}

	.progress-container :global(.progress-bar) {
		margin: 0;
	}

	.error-container p {
		color: var(--color-error);
		line-height: 1.25em;
		margin-bottom: 1.5rem;
	}
</style>
