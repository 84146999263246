<script lang="ts">
	import { run } from 'svelte/legacy';

	import { DataHandlerDevice, Device, DeviceGroup } from "luxedo-data"
	import { SelectedDeviceStore } from "../../../../stores/SelectedDeviceStore"
	import { PlusIcon } from "svelte-comps/icons"
	import { ProjectorRegistrationContext } from "../ProjectorRegistrationContext"
	import { TextRowSelector } from "svelte-comps/inputs"
	import DeviceList from "./DeviceList.svelte"

	let selectedOption: "My Devices" | "My Groups" = $state("My Devices")

	let projectors = filterDevices(DataHandlerDevice.getMany())
	let selectedDevice: Device = $state()

	let isSelectingOption: boolean = $state(false)
	let isRegistering: boolean = $state(false)
	let hasMultipleDevices: boolean = $state(projectors.length > 1)

	SelectedDeviceStore.subscribe((dev) => (selectedDevice = dev))
	ProjectorRegistrationContext.subscribe((ctx) => (isRegistering = ctx.isRegistering))

	DataHandlerDevice.addListener(() => {
		getRelevantDevices()
		hasMultipleDevices = projectors.length > 1
	})

	function filterDevices(devices: Array<Device>) {
		if (selectedOption === "My Devices")
			return devices.filter((dev) => !(dev instanceof DeviceGroup))
		if (selectedOption === "My Groups") return devices.filter((dev) => dev instanceof DeviceGroup)
	}

	function openOptions() {
		isSelectingOption = true
	}

	function createNewGroup() {
		ProjectorRegistrationContext.set({
			isRegistering: true,
			prevSelectedDevice: selectedDevice,
			colorIndex: undefined,
			type: "Group",
		})
		SelectedDeviceStore.set(undefined)
		isSelectingOption = false
	}

	function createNewLuxLink() {
		ProjectorRegistrationContext.set({
			isRegistering: true,
			prevSelectedDevice: selectedDevice,
			colorIndex: undefined,
			type: "LuxLink",
		})
		SelectedDeviceStore.set(undefined)
		isSelectingOption = false
	}

	function registerNew() {
		ProjectorRegistrationContext.set({
			isRegistering: true,
			prevSelectedDevice: selectedDevice,
			colorIndex: DataHandlerDevice.getNextUIColor(),
			type: "Cast",
		})
		SelectedDeviceStore.set(undefined)
		isSelectingOption = false
	}

	function cancelRegister() {
		isSelectingOption = false
	}

	function getRelevantDevices() {
		projectors = filterDevices(DataHandlerDevice.getMany())
	}

	run(() => {
		!hasMultipleDevices && (selectedOption = "My Devices")
	});
	run(() => {
		selectedOption && getRelevantDevices()
	});
</script>

{#if !isRegistering}
	<div class="device-filter-container">
		<div class="heading">
			{#if hasMultipleDevices}
				<TextRowSelector
					options={["My Devices", "My Groups"]}
					bind:selectedOption
					fontSize="var(--h1)"
				/>
			{:else}
				<h3>{isSelectingOption ? "Register" : "Select"} a Device</h3>
			{/if}
		</div>
		<div class="device-filter">
			<div class="new-projector-create-wrapper">
				{#if isSelectingOption && selectedOption === "My Devices"}
					<button class="new-projector-select" onclick={registerNew}>Register New Luxedo</button>

					<button class="new-projector-select" onclick={createNewLuxLink}
						>Create LuxLink Device</button
					>
					<button class="new-projector-select" onclick={cancelRegister}>Cancel</button>
				{:else if selectedOption === "My Devices"}
					<button
						id="begin-registration-button"
						class="new-projector-select {!selectedDevice ? 'selected' : ''}"
						onclick={openOptions}
					>
						<PlusIcon /> <span>Register New Device</span></button
					>
				{:else}
					<button
						id="begin-registration-button"
						class="new-projector-select {!selectedDevice ? 'selected' : ''}"
						onclick={createNewGroup}
					>
						<PlusIcon /> <span>New Projector Group</span></button
					>
				{/if}
			</div>
			{#if !isSelectingOption}
				<DeviceList deviceView={selectedOption === "My Devices" ? "Devices" : "Groups"} />
			{/if}
		</div>
	</div>
{/if}

<style>
	h3 {
		color: var(--color-text-light);
		margin: 0;
		font-size: var(--h1);
		text-align: center;
	}

	.heading {
		display: flex;
		flex-direction: column;
		align-items: center;
		margin-bottom: 1rem;
	}

	.device-filter {
		position: relative;
		--head-height: calc(var(--h1) + 1.5rem);
		height: calc(100% - var(--head-height));
		overflow-y: auto;
		overflow-x: hidden;
		padding-right: 0.25rem;
	}

	button.new-projector-select {
		transform: none;
		background-color: var(--color-bg);
		width: 100%;
		border-radius: 0.5rem;
		margin-bottom: 0.5rem;
		padding: 1rem;
		display: flex;
		align-items: center;

		transition:
			filter 250ms,
			color 250ms,
			box-shadow 250ms;
	}

	button.new-projector-select.selected,
	button.new-projector-select:hover,
	button.new-projector-select:focus-visible {
		/* filter: brightness(1.1); */
		color: var(--color-main);
	}

	button.new-projector-select.selected :global(.svg-stroke),
	button.new-projector-select:hover :global(.svg-stroke),
	button.new-projector-select:focus-visible :global(.svg-stroke) {
		stroke: var(--color-main);
	}

	.new-projector-select :global(svg) {
		width: 1rem;
		height: 1rem;
		margin-left: 0.5rem;
		margin-right: 1.5rem;
	}
</style>
