<script lang="ts">
	import { DataHandlerDevice, type DeviceGroupSlot } from "luxedo-data"
	import { GroupEditorController } from "../../../GroupEditorController"
	import InputsSlotPriorityInstance from "./Inputs_SlotPriorityInstance.svelte"
	import { GroupCanvasController } from "../../../canvas/GroupCanvasController"
	import { ToggleSwitch } from "svelte-comps/inputs"

	let orderedSlots: Array<DeviceGroupSlot> = $state()
	let showSnapshots: boolean = $state()

	GroupCanvasController.subscribe((ctx) => (showSnapshots = ctx.showSnapshots))

	function onSnapshotVisibilityUpdate(newVal: boolean) {
		GroupCanvasController.setSnapshotVisibility(newVal)
	}

	GroupEditorController.Slots.subscribe(
		({ slots }) => (orderedSlots = slots.sort((a, b) => b.priority - a.priority))
	)
</script>

<div class="slot-priority-container">
	<div class="flex-row">
		<h3>Devices</h3>
		<div class="switch-container">
			<span class="label"> Show Snapshots? </span>
			<ToggleSwitch isActive={showSnapshots} onUpdate={onSnapshotVisibilityUpdate} />
		</div>
	</div>
	<p class="info">
		Click a device below to edit its position or scale or drag it to adjust its render order.
	</p>
	<ul class="instance-selector">
		{#each orderedSlots as slot}
			<InputsSlotPriorityInstance {slot} />
		{/each}
	</ul>
</div>

<style>
	.slot-priority-container {
		flex-basis: 35%;
		padding-right: 2rem;
	}

	h3 {
		margin: 0;
		font-size: var(--h1);
	}

	.info {
		font-size: var(--h3);
		line-height: 1.5em;
	}

	.switch-container {
		display: flex;
		flex-direction: row;
		align-items: center;
		margin: 0 0 0 1rem;
	}

	.switch-container .label {
		font-size: var(--h3);
		margin-right: 0.5rem;
		color: var(--color-text);
	}
</style>
