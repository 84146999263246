<script lang="ts">
	import { DataHandlerDevice, type DeviceRPi } from "luxedo-data"
	import { GroupOverlapController } from "../GroupOverlapController"
	import ProjectorIcon from "../../../../../icons/ProjectorIcon.svelte"
	import { PlusIcon } from "svelte-comps/icons"

	interface Props {
		overlapInstance: GroupOverlapController.OverlapInstance
	}

	let { overlapInstance }: Props = $props()
	let isSelected: boolean = $state(false)

	GroupOverlapController.subscribe(
		(ctx) => (isSelected = overlapInstance.id === ctx.selectedOverlapInstance)
	)

	let devices: Array<DeviceRPi> = $state([])

	function getDevices() {
		if (!overlapInstance) return
		devices = overlapInstance.slots.map(
			(slot) => DataHandlerDevice.get(slot.device_id) as DeviceRPi
		)
	}

	$effect(() => {
		if (overlapInstance) getDevices()
	})
</script>

<div
	class="overlap-instance {isSelected ? 'selected' : ''}"
	onclick={() => GroupOverlapController.selectOverlap(overlapInstance.id)}
>
	<span class="device-name">
		<ProjectorIcon device={devices[0]} />
		{devices[0].name}
	</span>
	<PlusIcon />
	<span class="device-name">
		<ProjectorIcon device={devices[1]} />
		{devices[1].name}
	</span>
</div>

<style>
	.overlap-instance {
		display: flex;
		flex-direction: row;
		align-items: center;
		transition: color 250ms;
		cursor: pointer;
	}

	.overlap-instance :global(.plus-icon) {
		width: 1rem;
		margin: 0 0.5rem;
	}

	.overlap-instance :global(.plus-icon .svg-stroke) {
		stroke: var(--color-text-light);
	}

	.device-name :global(svg) {
		height: 1rem;
		width: 2rem;
	}

	.overlap-instance.selected,
	.overlap-instance:hover,
	.overlap-instance:focus-visible {
		color: var(--color-main);
	}

	.overlap-instance.selected :global(.plus-icon .svg-stroke),
	.overlap-instance:hover :global(.plus-icon .svg-stroke),
	.overlap-instance:focus-visible :global(.plus-icon .svg-stroke) {
		stroke: var(--color-main);
	}
</style>
