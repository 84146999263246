<script lang="ts">
	import type { Device } from "luxedo-data"
	import { LuxedoRPC } from "luxedo-rpc"
	import { closeOverlay, openOverlay } from "svelte-comps/overlay"
	import { Toast } from "svelte-comps/toaster"
	import AdvancedActionsRollbackReport from "./AdvancedActions_RollbackReport.svelte"

	interface Props {
		device: Device;
		disabled: boolean;
		disableButtons: () => void;
	}

	let { device, disabled, disableButtons }: Props = $props();

	async function verifyRollback() {
		return new Promise<void>((res, rej) => {
			const id = openOverlay(AdvancedActionsRollbackReport, {
				heading: "Rollback Beta Update",
				classHeading: "no-underline",
				props: {
					postSubmit: () => {
						res()
						closeOverlay(id)
					},
				},
			})
		})
	}

	async function rollbackDevice() {
		await verifyRollback()

		Toast.text(
			"Rolling back firmware version... Please give up to 10 minutes before restarting your device."
		)

		await LuxedoRPC.api.deviceControl.device_rollback(device.id)
		disableButtons()
	}
</script>

<div class="flex-row">
	<button class="outline-button" id="rollback-button" onclick={rollbackDevice} {disabled}
		>Rollback beta update</button
	>
	<span class="info">Rolls back to the previous firmware version.</span>
</div>

<style>
	#rollback-button {
		--border-color: var(--color-alt-5);
	}
</style>
