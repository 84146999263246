<script lang="ts">
	import { DeviceGroup } from "luxedo-data"
	import { SelectedDeviceStore } from "../../../../../../stores/SelectedDeviceStore"
	import { CollapsibleSection } from "svelte-comps/comps"
	import { ArrowUp } from "svelte-comps/icons"
	import { Toast } from "svelte-comps/toaster"
	import {
		ProjectorActionsController,
		type ProjectorActionType,
	} from "./ProjectorActionsController"
	import { LuxedoRPC } from "luxedo-rpc"

	let device: DeviceGroup

	let expanded: boolean = $state()
	let isDisabled: boolean = $state(false)
	let timeout

	SelectedDeviceStore.subscribe((dev) => {
		if (dev != device && dev instanceof DeviceGroup) {
			device = dev
			isDisabled = false
			clearTimeout(timeout)
		} else {
			device = undefined
		}
	})

	let availActions: Array<ProjectorActionType> = $state([])
	ProjectorActionsController.subscribe((actions) => {
		availActions = actions
	})

	async function stopPlayback() {
		disableButtons()
		Toast.text("Stopping playback...")
		await LuxedoRPC.api.device.device_group_clear_playback(device.id)
	}

	async function restartDevice() {
		disableButtons()
		Toast.text("Restarting devices... This may take a few minutes.")
		await LuxedoRPC.api.device.device_group_restart_children(device.id)
	}

	function disableButtons() {
		isDisabled = true
		timeout = setTimeout(() => {
			isDisabled = false
		}, 8000)
	}
</script>

<div class="advanced-actions">
	<CollapsibleSection className="projector-heading" bind:expanded>
		{#snippet header()}
				<div class="flex-row heading {expanded ? 'open' : ''}" >
				<h2 class="setting-heading">Actions</h2>
				{#if !expanded}
					<span class="info">click to access additional group actions</span>
				{/if}
				<button class="icon small"><ArrowUp /></button>
			</div>
			{/snippet}
		{#snippet content()}
				<div >
				<div class="buttons">
					{#if availActions.includes("REBOOT")}
						<div class="flex-row">
							<button class="outline-button alt" onclick={restartDevice} disabled={isDisabled}
								>Restart devices</button
							>
							<span class="info">Reboot all devices in group. </span>
						</div>
					{/if}
					{#if availActions.includes("STOP_PLAYBACK")}
						<div class="flex-row">
							<button class="outline-button" onclick={stopPlayback} disabled={isDisabled}
								>Stop playback</button
							>
							<span class="info">Stop any current playback. </span>
						</div>
					{/if}
				</div>
			</div>
			{/snippet}
	</CollapsibleSection>
</div>

<style>
</style>
