<script lang="ts">
	import { run } from 'svelte/legacy';

	import { Scene, type Lightshow } from "luxedo-data"
	import { CollapsibleSection } from "svelte-comps/comps"
	import { ArrowDown } from "svelte-comps/icons"
	import DesignTile from "../../../../reusable/file-system/tiles/DesignTile.svelte"
	import LightshowTile from "../../../../reusable/file-system/tiles/LightshowTile.svelte"
	import { tooltip } from "svelte-comps/tooltip"
	import { openShowOverview } from "../../../../reusable/overlays"


	interface Props {
		shows: Array<Scene | Lightshow>;
		heading: string;
		emptyText: string;
		info?: string;
	}

	let {
		shows,
		heading,
		emptyText,
		info = undefined
	}: Props = $props();

	let isExpanded: boolean = $state(true)
	let filteredShows: Array<Scene | Lightshow> = $state([])

	// removes any duplicates
	run(() => {
		filteredShows = shows.filter(
			(show1, index, self) => self.findIndex((show2) => show2.id == show1.id) === index
		)
	});
</script>

<CollapsibleSection className="download-list" bind:expanded={isExpanded}>
	{#snippet header()}
		<h2
			class="setting-heading"
			
			use:tooltip={info
				? {
						pointing: "top",
						content: "",
					}
				: undefined}
		>
			{heading}
			<button class="small icon {isExpanded ? 'open' : ''}"><ArrowDown /></button>
		</h2>
	{/snippet}
	{#snippet content()}
		<div  class="flex-row show-list">
			{#if shows.length > 0}
				{#each filteredShows as show}
					{#if show instanceof Scene}
						<DesignTile design={show} onClick={() => openShowOverview(show)} />
					{:else}
						<LightshowTile lightshow={show} onClick={() => openShowOverview(show)} />
					{/if}
				{/each}
			{:else}
				<span class="info"> {emptyText} </span>
			{/if}
		</div>
	{/snippet}
</CollapsibleSection>

<style>
	.setting-heading {
		margin: 0;
		display: flex;
		flex-direction: row;
		color: var(--color-text);
		font-size: var(--h1);
	}

	button :global(svg) {
		transition: transform 250ms;
	}

	button.small.icon.open :global(svg) {
		transform: rotateX(180deg);
	}

	.info {
		color: var(--color-text);
	}

	.show-list {
		overflow: hidden;
		overflow-x: auto;
		gap: 1rem;
		padding-top: 0.5rem;
	}

	.show-list:not(:has(.info)) {
		padding-bottom: 1rem;
	}
</style>
