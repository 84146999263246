<script lang="ts">
	import { DeviceRPi, type Device } from "luxedo-data"
	import { SelectedDeviceStore } from "../../../../../../stores/SelectedDeviceStore"
	import AdvancedActionsHdmiFix from "./AdvancedActions_HDMIFix.svelte"
	import { CollapsibleSection } from "svelte-comps/comps"
	import { ArrowUp } from "svelte-comps/icons"
	import { Toast } from "svelte-comps/toaster"
	import {
		ProjectorActionsController,
		type ProjectorActionType,
	} from "./ProjectorActionsController"
	import { LuxedoRPC } from "luxedo-rpc"
	import AdvancedActionsRollbackUpdate from "./AdvancedActions_RollbackUpdate.svelte"

	let device: Device = $state()

	let expanded: boolean = $state()
	let isDisabled: boolean = $state(false)
	let timeout

	SelectedDeviceStore.subscribe((dev) => {
		if (dev != device) {
			device = dev
			isDisabled = false
			clearTimeout(timeout)
		}
	})

	let availActions: Array<ProjectorActionType> = $state([])
	ProjectorActionsController.subscribe((actions) => {
		availActions = actions
	})

	async function stopPlayback() {
		Toast.text("Stopping playback...")
		await device.platoCall("clear_override", [])
		disableButtons()
	}

	// async function restartDevice() {
	// 	Toast.text("Restarting device... This may take a few minutes.")
	// 	await device.platoCall("reboot", [])
	// 	disableButtons()
	// }

	function disableButtons() {
		isDisabled = true
		timeout = setTimeout(() => {
			isDisabled = false
		}, 8000)
	}
</script>

<div class="advanced-actions">
	<CollapsibleSection className="projector-heading" bind:expanded>
		{#snippet header()}
				<div class="flex-row heading {expanded ? 'open' : ''}" >
				<h2 class="setting-heading">Actions</h2>
				{#if !expanded}
					<span class="info">click to access additional device actions</span>
				{/if}
				<button class="icon small"><ArrowUp /></button>
			</div>
			{/snippet}
		{#snippet content()}
				<div >
				<div class="buttons">
					<!-- {#if availActions.includes("REBOOT")}
						<div class="flex-row">
							<button class="outline-button alt" on:click={restartDevice} disabled={isDisabled}
								>Restart device</button
							>
							<span class="info">Reboot device completely. </span>
						</div>
					{/if} -->
					{#if availActions.includes("STOP_PLAYBACK")}
						<div class="flex-row">
							<button class="outline-button" onclick={stopPlayback} disabled={isDisabled}
								>Stop playback</button
							>
							<span class="info">Stop any current projection. </span>
						</div>
					{/if}
					{#if availActions.includes("HDMI_FIX")}
						<div class="flex-row">
							<AdvancedActionsHdmiFix {device} disabled={isDisabled} />
							<span class="info">Fixes an issue where the connected projector can flash blue. </span>
						</div>
					{/if}
					{#if availActions.includes("FW_ROLLBACK")}
						<AdvancedActionsRollbackUpdate {device} disabled={isDisabled} {disableButtons} />
					{/if}
				</div>
			</div>
			{/snippet}
	</CollapsibleSection>
</div>

<style>
</style>
