<script lang="ts">
	import { ArrowBack, ArrowForward } from "../icons"
	import LoadingSpinner from "../loading-indicator/LoadingSpinner.svelte"

	interface Props {
		images: Array<string>
	}

	let { images }: Props = $props()
	let index = $state(0)

	function seeNext() {
		index++
		ensureIndexWithinBounds()
	}

	function seeLast() {
		index--
		ensureIndexWithinBounds()
	}

	function ensureIndexWithinBounds() {
		if (index >= images.length) index = 0
		else if (index < 0) index = images.length - 1
	}
</script>

<div class="image-viewer">
	{#if images && images.length}
		<img src={images[index]} alt="" />
		{#if images.length > 1}
			<div class="controls">
				<button class="icon" onclick={seeLast}><ArrowBack /></button>
				<button class="icon" onclick={seeNext}><ArrowForward /></button>
			</div>
			<span class="index-indicator">{index + 1}/{images.length}</span>
		{/if}
	{:else}
		<LoadingSpinner height="8rem" />
	{/if}
</div>

<style>
	.image-viewer {
		flex-grow: 1;
		position: relative;
		overflow: hidden;
		border-radius: var(--br);
		display: flex;
		justify-content: center;
	}

	img {
		object-fit: contain;

		border-radius: var(--br);
		height: auto;
		width: auto;
	}

	.controls button :global(.svg-stroke) {
		stroke: var(--color-text-light);
	}

	.controls button:hover :global(.svg-stroke),
	.controls button:focus-visible :global(.svg-stroke) {
		stroke: var(--color-main);
	}

	.controls button :global(svg) {
		padding: 0 0.5rem;
	}

	.controls button {
		border-radius: unset;
		height: 3.5rem;
		width: 3rem;
		background-color: var(--color-bg);
		position: absolute;
		bottom: 0;
		left: 0;
		border-top-right-radius: 0.5rem;
	}

	.controls button:last-of-type {
		left: unset;
		right: 0;
		border-radius: unset;
		border-top-left-radius: 0.5rem;
	}

	.index-indicator {
		position: absolute;
		top: 0;
		right: 0;
		padding: 0.5rem 1rem;
		background-color: var(--color-bg);
		border-bottom-left-radius: 0.5rem;
	}
</style>
