<script lang="ts">
	import { DataHandlerDevice, type Device } from "luxedo-data"

	// Top level '/projectors' page
	import ProjectorSelector from "./projector-selection/ProjectorSelector.svelte"
	import { SelectedDeviceStore } from "../../../stores/SelectedDeviceStore"
	import ProjectorInfo from "./projector-info/ProjectorInfo.svelte"
	import { onDestroy, onMount } from "svelte"
	import DeviceNextSteps from "./next-steps/DeviceNextSteps.svelte"
	import { ScheduleController } from "../schedule/ScheduleController"
	import DeviceFilter from "../../reusable/device-filter/DeviceFilter.svelte"

	let device: Device
	let calendarRefresh: Promise<void> = $state()
	let hasDevice: boolean = $state(false)

	const unsubscribe = SelectedDeviceStore.subscribe((dev) => {
		if (!dev) return
		device = dev
		calendarRefresh = ScheduleController.Calendar.refreshEvents({ deviceFilter: dev })
	})

	function updateDeviceAmount() {
		hasDevice = DataHandlerDevice.getMany().length > 0
	}

	function clearListeners() {
		unsubscribe()
		DataHandlerDevice.removeListener(updateDeviceAmount)
	}

	onDestroy(clearListeners)

	onMount(() => {
		updateDeviceAmount()
		DataHandlerDevice.addListener(updateDeviceAmount)

		setTimeout(() => {
			const urlParams = new URLSearchParams(window.location.search)
			const deviceId = Number(urlParams.get("device"))
			if (deviceId) {
				const searchDevice = DataHandlerDevice.get(deviceId)
				device = searchDevice
				if (searchDevice) SelectedDeviceStore.set(searchDevice)
			}

			if (!device) {
				SelectedDeviceStore.set(DataHandlerDevice.getMany()[0])
			}
		})
	})
</script>

<div id="projectors">
	<div id="projectors-left">
		<div id="projectors-heading" class="flex-row">
			<h1>Projectors</h1>
		</div>
		<ProjectorInfo />
	</div>
	<div id="projectors-right">
		{#if hasDevice}
			<ProjectorSelector />
		{/if}
		{#await calendarRefresh then}
			<DeviceNextSteps />
		{/await}
	</div>
</div>

<style>
	#projectors {
		height: 100%;
		padding: 0;
		padding-bottom: 1rem;
		display: flex;
		flex-direction: row;
		width: 100%;
	}

	#projectors-heading {
		justify-content: space-between;
	}

	h1 {
		font-size: var(--h1);
		color: var(--color-text-light);
		margin: 0 0 0 2rem;
		line-height: 3rem;
	}

	#projectors-left {
		flex-grow: 1;
		margin-right: 2rem;
		display: flex;
		flex-direction: column;
		overflow: hidden;
	}

	#projectors-right {
		display: flex;
		flex-direction: column;
		flex-basis: 40%;
		max-width: 20rem;
		min-width: 20rem;
		overflow: hidden;
		gap: 1rem;

		/* height: 12rem; */
	}

	#projectors-right :global(.device-filter-container) {
		max-height: 50%;
	}

	#projectors-left :global(.icon-container) {
		position: relative;
		display: flex;
		align-items: center;
		justify-content: center;
		padding: 0 2rem;
	}

	#projectors-left :global(.icon-container svg) {
		width: 4rem;
		height: 4rem;
		z-index: 5;
	}

	#projectors-left :global(.icon-container .icon-bg) {
		position: absolute;
		width: 500%;
		height: 500%;
		backdrop-filter: blur(2rem);
		z-index: -1;
	}

	#projectors-left :global(.icon-container .icon-bg-color) {
		position: absolute;
		width: 2rem;
		height: 2rem;
		border-radius: 100%;
		z-index: -2;
	}

	:global(.low-pad) {
		padding: 1rem 0 0 0;
	}
</style>
