import {
	writable,
	get as storeGet,
	type Unsubscriber,
	type Writable,
} from "svelte/store"

/**
 * Extendable controller. Use this as the base for component level controllers.
 */
export abstract class Controller<ContextType> {
	store: Writable<ContextType>
	contextDefault: ContextType

	constructor(ctxDefault: ContextType) {
		this.contextDefault = ctxDefault
		this.store = writable<ContextType>(ctxDefault)
	}

	protected reset() {
		this.store.set(this.contextDefault)
	}

	subscribe(cb: (ctx: ContextType) => void): Unsubscriber {
		return this.store.subscribe(cb)
	}

	update(content: Partial<ContextType>) {
		return this.store.update((ctx) => ({ ...ctx, ...content }))
	}

	get(): ContextType
	get<K extends keyof ContextType>(property: K): ContextType[K]
	get<K extends keyof ContextType>(property?: K) {
		const ctx = storeGet(this.store)
		if (property) return ctx[property]
		return ctx
	}
}
