<script lang="ts">
	import { Device, DeviceRPi } from "luxedo-data"
	import { asyncConfirmOverlay } from "svelte-comps/overlay"
	import { Toast } from "svelte-comps/toaster"

	interface Props {
		device: Device;
		disabled?: boolean;
	}

	let { device, disabled = false }: Props = $props();
	let boostStatus: boolean

	function getHDMIBoostStatus() {
		boostStatus = (device as DeviceRPi)?.eidos?.config?.fw_config?.hdmi_boost ?? false
		return boostStatus
	}

	async function applyHDMIBoost() {
		await asyncConfirmOverlay({
			prompt: [
				"This setting can fix an issue where the projection intermittently disconnects or projects blue.",
				"Your device will restart after applying this setting.",
				"Do you wish to continue?",
			],
			confirmText: "Yes",
			denyText: "Cancel",
			title: "Apply HDMI Fix",
		})
		Toast.text("Applying HDMI fix and restarting device... This may take 5 minutes.")
		await device.platoCall("hdmi_boost_apply", [4])
	}

	async function removeHDMIBoost() {
		await asyncConfirmOverlay({
			prompt: [
				"If you've applied this fix but haven't yet restarted the device by removing and replacing the power, it's recommended to power cycle first, as this can resolve the issue after applying the fix. ",
				"Your device will restart after removing this setting.",
				"Do you wish to continue?",
			],
			confirmText: "Yes",
			denyText: "Cancel",
			title: "Remove HDMI Fix",
		})
		Toast.text("Removing HDMI fix and restarting device... This may take 5 minutes.")
		await device.platoCall("hdmi_boost_remove", [])
	}
</script>

{#if !getHDMIBoostStatus()}
	<button
		class="outline-button hdmi-button"
		title="Apply HDMI Fix"
		onclick={applyHDMIBoost}
		{disabled}>Apply HDMI Fix</button
	>
{:else}
	<button
		class="outline-button hdmi-button"
		title="Remove HDMI Fix"
		onclick={removeHDMIBoost}
		{disabled}>Remove HDMI Fix</button
	>
{/if}

<style>
	.hdmi-button {
		--border-color: var(--color-accent);
	}
</style>
