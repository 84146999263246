<script lang="ts">
	import {
		DataHandlerDevice,
		DeviceRPi,
		type Device,
		DeviceBrowser,
		DeviceGroup,
	} from "luxedo-data"
	import { SelectedDeviceStore } from "../../../../../stores/SelectedDeviceStore"
	import { Toast } from "svelte-comps/toaster"
	import LuxedoRadioSettings from "./LuxedoRadioSettings.svelte"
	import PasswordInput from "../../../../reusable/input/PasswordInput.svelte"
	import { DataSaveError } from "../../../../../types/ErrorVariants"
	import ProjectorIcon from "../../../../reusable/icons/ProjectorIcon.svelte"

	interface Props {
		triggerSave: () => Promise<void>
	}

	let { triggerSave }: Props = $props()

	let device: Device = $state()

	let deviceName: string = $state()
	let devicePass: string = $state()
	let children: Array<Device> = $state()

	let firmwareVersion = $state()
	let deviceUpdateListener

	SelectedDeviceStore.subscribe((dev) => {
		device = dev
		deviceName = device?.name
		devicePass = device?.password
		if (device instanceof DeviceGroup) children = device.getChildDevices()
		else children = undefined

		if (device instanceof DeviceRPi) {
			firmwareVersion = device.firmwareVersion

			deviceUpdateListener = device.addUpdateListener((device) => {
				setTimeout(() => (firmwareVersion = device.firmwareVersion))
			})
		}
	})

	triggerSave = async () => {
		try {
			let hasUpdated
			if (deviceName !== device.name) {
				await renameDevice()
				hasUpdated = true
			}

			if (hasUpdated) Toast.success("Device updated successfully!")
			else Toast.text("No changes detected.")
		} catch (e) {
			console.error("[ERROR] ", e)
			if (e instanceof DataSaveError) Toast.error(e.message)
			else throw e
		}
	}

	async function renameDevice() {
		if (deviceName !== device.name) {
			device.name = deviceName

			try {
				await DataHandlerDevice.save(device)
			} catch (e) {
				console.error(`Error renaming device`, e)
				throw new DataSaveError("Unable to rename device.")
			}

			DataHandlerDevice.pull([device.id])
			const updatedDevice = DataHandlerDevice.get(device.id)
			SelectedDeviceStore.set(updatedDevice)
		}
	}

	// #endregion Data Save Operations

	// #region DOM Event Listeners

	function onKeydown(e: KeyboardEvent) {
		if (e.key === "Enter") triggerSave()
	}

	// #endregion DOM Event Listeners
</script>

<div class="device-properties">
	<div class="flex-column">
		<div class="input-container">
			<label for="name-input">Device Name: </label>
			<input
				id="name-input"
				type="text"
				onkeydown={onKeydown}
				bind:value={deviceName}
			/>
		</div>
		{#if devicePass}
			<div class="input-container">
				<label for="password-input">Device Password: </label>
				<PasswordInput
					value={devicePass}
					id="device-password-input"
					disabled={true}
					readonly={true}
				/>
			</div>
		{/if}
		{#if children && children.length}
			<div class="child-device-overview">
				<span class="label">Group Devices: </span>
				<ul>
					{#each children as child}
						<li
							class="child-device"
							onclick={() => SelectedDeviceStore.set(child)}
						>
							<ProjectorIcon device={child} />
							<span class="child-device-name" style="color: {child.color}; "
								>{child?.name}</span
							>
						</li>
					{/each}
				</ul>
			</div>
		{/if}
	</div>
	<LuxedoRadioSettings />
</div>

<div id="info-bottom">
	<div class="device-info">
		<span class="device-type"> {device.type} </span>
		<span>
			Device ID: {device.id}
		</span>
		{#if firmwareVersion}
			<span>
				Firmware Version: {firmwareVersion}
			</span>
		{/if}
	</div>
</div>

<style>
	.device-properties :global(input) {
		width: 100%;
		margin-left: 1rem;
		height: 1.5rem;
		transition: background-color 250ms;
	}

	label {
		white-space: nowrap;
		width: fit-content;
	}

	.device-properties :global(input:focus-visible),
	.device-properties :global(input:hover) {
		background-color: var(--color-bg);
	}

	#info-bottom {
		display: flex;
		width: 100%;
		flex-direction: column;
		align-items: center;
	}

	.device-properties {
		width: 100%;
		flex-grow: 1;
		margin-bottom: 0.5rem;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
	}

	.device-properties .flex-column:first-of-type {
		margin-bottom: 1rem;
	}

	.device-properties .flex-column :global(input) {
		width: 100%;
	}

	.device-info {
		display: flex;
		flex-direction: column;
		align-items: center;
		color: var(--color-text);

		/* position: absolute;
		bottom: 0;
		left: 0;
		right: 0;
		margin-left: auto;
		margin-right: auto; */
	}

	.device-info span:nth-of-type(2) {
		padding-top: 0.5rem;
		line-height: 1rem;
	}

	.child-device-overview {
		display: flex;
		flex-direction: row;
	}

	.child-device-overview .label {
		color: var(--color-text);
		margin-right: 1rem;
	}

	.child-device :global(svg) {
		height: 1rem;
		width: auto;
	}

	.child-device-name {
		cursor: pointer;
	}

	.child-device .child-device-name:hover {
		text-decoration: underline;
	}
</style>
