<script lang="ts">
	import type { Device } from "luxedo-data"
	import {
		AdvancedSettingsController,
		type AdvancedSettingProperty_Name,
	} from "../ProjectorAdvancedController"
	import { SelectedDeviceStore } from "../../../../../../stores/SelectedDeviceStore"

	import AdvancedInputsProjector from "./AdvancedInputs_Projector.svelte"
	import AdvancedInputsCamera from "./AdvancedInputs_Camera.svelte"
	import AdvancedInputsAudio from "./AdvancedInputs_Audio.svelte"

	let device: Device
	let availableProperties: AdvancedSettingProperty_Name[] = $state()
	SelectedDeviceStore.subscribe((dev) => {
		device = dev
		availableProperties = AdvancedSettingsController.getAvailableProperties(device)
	})

	function containsAny(availableProperties: Array<string>, compareProperties: Array<string>) {
		return availableProperties.some((str) => compareProperties.includes(str))
	}

	function hasAudioInputs(availableProperties: AdvancedSettingProperty_Name[]) {
		return containsAny(availableProperties, ["audioOutput"])
	}

	function hasProjectorInputs(availableProperties: AdvancedSettingProperty_Name[]) {
		return containsAny(availableProperties, ["isTimeoutActive", "powerStatus", "timeoutDuration"])
	}

	function hasCameraInputs(availableProperties: AdvancedSettingProperty_Name[]) {
		return containsAny(availableProperties, ["cameraExposure", "invertCamera"])
	}
</script>

<div class="advanced-inputs">
	{#if hasProjectorInputs(availableProperties)}
		<AdvancedInputsProjector />
	{/if}

	{#if hasCameraInputs(availableProperties)}
		<AdvancedInputsCamera />
	{/if}
	{#if hasAudioInputs(availableProperties)}
		<AdvancedInputsAudio />
	{/if}
</div>

<style>
	.advanced-inputs {
		padding: 0 1rem;
	}

	.advanced-inputs :global(.projector-timeout-container .switch-container) {
		width: 12.25rem;
	}

	.advanced-inputs :global(#projector-resolution-label) {
		display: flex;
		flex-direction: row;
	}

	.advanced-inputs :global(.switch-container) {
		width: 11rem;
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: space-between;
	}

	.advanced-inputs :global(.button-container) {
		flex-direction: column;
		align-items: flex-start;
		margin-top: 1rem;
	}

	.advanced-inputs :global(.flex-row) {
		align-items: center;
	}

	.advanced-inputs :global(.invert-camera-container :global(.info-dialogue-button)) {
		margin-left: 0;
		margin-bottom: 0.5rem;
	}

	.advanced-inputs :global(.label),
	.advanced-inputs :global(label) {
		color: var(--color-text);
		white-space: nowrap;
		width: fit-content;
	}

	.advanced-inputs :global(select) {
		color: var(--color-text-light);
		width: 100%;
		margin-left: 1rem;
		transition: background-color 250ms;
	}

	.advanced-inputs :global(input[type="number"]) {
		background-color: unset;
		color: var(--color-text-light);
		margin: 0 0.5rem;
		border: none;
		padding: 0;
		box-shadow: none;
		width: 2rem;
		border-radius: 0;
		text-align: end;
	}

	.advanced-inputs :global(select:focus-visible),
	.advanced-inputs :global(select:hover) {
		background-color: var(--color-main-transparent);
	}

	.advanced-inputs :global(.power-control-info) {
		margin-left: 0.5rem;
		color: var(--color-text);
		font-size: var(--h3);
	}

	.advanced-inputs :global(.setting-heading) {
		margin: 0;
		color: var(--color-text-light);
		font-size: var(--h1);
	}

	.advanced-inputs :global(.projector-heading:not(:first-of-type)) {
		margin-top: 0.5rem;
	}

	.advanced-inputs > :global(div:not(.projector-heading)) {
		margin-left: 1rem;
	}
</style>
