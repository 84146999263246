<script lang="ts">

	import { DataHandlerDevice, DeviceGroup, type Device } from "luxedo-data"
	import ProjectorIcon from "../icons/ProjectorIcon.svelte"
	import { SettingsIcon } from "svelte-comps/icons"
	import { navigateTo } from "../../../stores/NavigationContext"
	import { SelectedDeviceStore } from "../../../stores/SelectedDeviceStore"

	interface Props {
		device: Device
		filterDevice: Device
		hideSettings?: boolean
		onSetDeviceFilter: (device: Device) => void
	}

	let {
		device = $bindable(),
		filterDevice,
		hideSettings = false,
		onSetDeviceFilter,
	}: Props = $props()

	let listenerInfo: {
		deviceId: number
		listenerId: string
	} = undefined

	let deactivated = $state(false)

	function updateListeners() {
		if (listenerInfo && listenerInfo.listenerId) {
			DataHandlerDevice.get(listenerInfo.deviceId)?.removeUpdateListener(listenerInfo.listenerId)
		}

		const newListenerId = device.addUpdateListener((dev) => {
			device = dev

			if (device instanceof DeviceGroup && device.deactivated) {
				deactivated = true
			} else {
				deactivated = false
			}
		})

		listenerInfo = {
			listenerId: newListenerId,
			deviceId: device.id,
		}
	}

	function setFilterDevice() {
		if (filterDevice === device) return onSetDeviceFilter(undefined)

		onSetDeviceFilter(device)
	}

	function openProjectorSettings(e: MouseEvent) {
		e.stopPropagation()
		SelectedDeviceStore.set(device)
		navigateTo("projector")
	}

	$effect(() => {
		if (device) updateListeners()
	})
</script>

<div
	class="projector-filter-instance {filterDevice === device ? 'isSelected' : ''} {deactivated
		? 'deactivated'
		: ''}"
	style="--color-device: {device.color};"
	role="button"
	tabindex="0"
	onkeydown={(e) => {
		if (e.key === "Enter") setFilterDevice()
	}}
	onclick={setFilterDevice}
>
	<div class="projector-icon">
		<ProjectorIcon {device} />
	</div>
	<div class="projector-info">
		<span class="projector-name">
			{device.name}
		</span>
		{#if !deactivated}
			<span class="projector-status text-small">
				<div
					class="projector-status-indicator"
					style="background-color: {device.statusColor};"
				></div>
				{device.status}
			</span>
		{/if}
	</div>
	{#if !hideSettings}
		<button onclick={openProjectorSettings} class="icon settings-icon">
			<SettingsIcon />
		</button>
	{/if}
</div>

<style>
	.projector-filter-instance {
		box-sizing: border-box;
		cursor: pointer;
		display: flex;
		background-color: var(--color-bg);
		margin: 2px;
		margin-bottom: 0.5rem;
		border-radius: var(--br);
		align-items: center;
		height: 4rem;
		width: 100%;
		transition:
			filter 250ms,
			color 250ms,
			box-shadow 250ms;
		box-shadow: var(--shadow-small);
	}

	.projector-filter-instance:hover,
	.projector-filter-instance:focus-visible {
		color: var(--color-device);
	}

	.projector-filter-instance.deactivated {
		opacity: 0.6;
		transition:
			opacity 500ms,
			color 500ms;
		color: var(--color-text);
	}

	.projector-filter-instance.deactivated:hover,
	.projector-filter-instance.deactivated:focus-visible,
	.projector-filter-instance.deactivated.isSelected {
		opacity: 0.85;
		color: var(--color-text-light);
		outline: 1px solid var(--color-accent);
	}

	.projector-filter-instance.isSelected {
		box-shadow: var(--shadow-large);
		outline: 3px solid var(--color-device);
		outline-offset: -3px;
	}

	.projector-status-indicator {
		width: 0.5rem;
		height: 0.5rem;
		border-radius: 100px;
		margin-right: 0.25rem;
	}

	.projector-info {
		display: flex;
		flex-direction: column;
		flex-grow: 1;
		overflow: hidden;
	}

	.projector-name {
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}

	.projector-status {
		display: flex;
		flex-direction: row;
		align-items: center;
		line-height: 1em;
		margin-top: 0.25rem;
		color: var(--color-text);
	}

	.projector-icon {
		padding: 0 1rem;
		height: fit-content;
	}

	.projector-icon :global(svg) {
		width: 2rem;
		margin-top: 0.3rem;
	}

	.settings-icon {
		margin-right: 0.5rem;
	}

	.settings-icon :global(svg) {
		height: 1.5rem;
	}

	.settings-icon :global(.svg-fill) {
		fill: var(--color-text);
	}

	.settings-icon :global(.svg-stroke) {
		stroke: var(--color-text);
	}

	.settings-icon:hover :global(.svg-fill),
	.settings-icon:focus-visible :global(.svg-fill) {
		fill: var(--color-text-light);
	}

	.settings-icon:hover :global(.svg-stroke),
	.settings-icon:focus-visible :global(.svg-stroke) {
		stroke: var(--color-text-light);
	}
</style>
