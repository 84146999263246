<script lang="ts">
	import LoadingSpinner from "../../../../../loaders/LoadingSpinner.svelte"
	import { GroupEditorController } from "../../../GroupEditorController"
	import GroupSettings from "../../GroupSettings.svelte"
	import { GroupOverlapController } from "../GroupOverlapController"
	import InputsOverlapProperties from "./Inputs_OverlapProperties.svelte"
	import InputsSlotOverlaps from "./Inputs_SlotOverlaps.svelte"

	let isSaving = $state(false)

	async function save() {
		isSaving = true
		await GroupEditorController.save(true)
		isSaving = false
	}
</script>

<div id="group-blend-inputs">
	<InputsSlotOverlaps />
	<InputsOverlapProperties />
	<GroupSettings />
</div>

<div class="bottom-row">
	<!-- <div class="step-buttons"> -->
	<button id="back-step-button" class="link-button" onclick={() => GroupOverlapController.back()}>
		Back
	</button>
	{#if isSaving}
		<div class="button-padding">
			<LoadingSpinner color="var(--color-main)" height={"2rem"} />
		</div>
	{:else}
		<button id="next-step-button" class="outline-button" onclick={() => save()}> Save </button>
	{/if}
	<!-- </div> -->
</div>

<style>
	#group-blend-inputs {
		padding: 2rem;
		padding-bottom: 4rem;
		display: flex;
		flex-direction: row;
	}

	.button-padding {
		width: 6rem;
		display: flex;
		flex-direction: row;
		justify-content: center;
	}

	.bottom-row {
		display: flex;
		flex-direction: row;
		padding: 0 1rem;

		position: absolute;
		width: 100%;
		bottom: 1rem;
		justify-content: flex-end;
	}
</style>
