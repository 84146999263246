<script lang="ts">
	import { DataHandlerDevice, Snapshot, type DeviceGroupSlot, type DeviceRPi } from "luxedo-data"
	import { GroupEditorController } from "../GroupEditorController"
	import { GroupCanvasController } from "./GroupCanvasController"
	import { onDestroy } from "svelte"
	import { Toast } from "svelte-comps/toaster"
	import LockIcon from "../../../icons/LockIcon.svelte"

	interface Props {
		slot: DeviceGroupSlot
		onClick?: () => void
	}

	let { slot, onClick = undefined }: Props = $props()

	let isPositionLocked: boolean = $state()

	let device: DeviceRPi = $derived(getDevice(slot))

	let snapshot: Snapshot = $state()
	let gridImage: string = $state()

	let isSelected: boolean = $state(false)
	let isDragging: boolean = $state(false)

	let zoomLevel: number
	let showSnapshots: boolean = $state()

	let tempPosX: number = $state(),
		tempPosY: number = $state()
	let element: HTMLDivElement = $state()

	GroupEditorController.Slots.subscribe((ctx) => {
		isSelected = ctx.selectedSlot === slot.id
		isPositionLocked = ctx.positionLockedSlots[slot.id]
	})

	GroupEditorController.Grid.subscribe((ctx) => {
		gridImage = ctx.images[slot.id]
	})

	GroupCanvasController.subscribe((ctx) => {
		zoomLevel = ctx.zoomLevel
		showSnapshots = ctx.showSnapshots
	})

	function handleSlotMouseDown(
		e: MouseEvent & {
			currentTarget: HTMLDivElement
		}
	) {
		if (e.button !== 0 || !onClick) return
		onClick()

		const startPos = {
			x: e.clientX,
			y: e.clientY,
		}

		const updatePosition = () => {
			if (tempPosX !== undefined) {
				GroupEditorController.Slots.updateSlot({
					pos_x: Math.floor(tempPosX),
					pos_y: Math.floor(tempPosY),
				})

				setTimeout(() => {
					const { right, bottom } = GroupCanvasController.getContainerBounds()

					if (
						element.getBoundingClientRect().right < right ||
						element.getBoundingClientRect().bottom < bottom
					)
						GroupCanvasController.refreshZoom()
				})

				tempPosX = undefined
				tempPosY = undefined
			}

			isDragging = false
			document.removeEventListener("mousemove", handler)
			document.removeEventListener("mouseup", updatePosition)
		}

		const handler = (e: MouseEvent) => {
			if (isPositionLocked) {
				document.removeEventListener("mousemove", handler)
				return Toast.error("Position is locked for that device.")
			}

			const baseX = slot.pos_x
			const baseY = slot.pos_y

			const movementX = (e.clientX - startPos.x) / zoomLevel
			const movementY = (e.clientY - startPos.y) / zoomLevel

			tempPosX = baseX + movementX
			tempPosY = baseY + movementY

			isDragging = true
		}

		document.addEventListener("mousemove", handler)
		document.addEventListener("mouseup", updatePosition)
	}

	function onKeyDown(e: KeyboardEvent) {
		if (isPositionLocked) return Toast.error("Position is locked for that device.")

		isDragging = true

		switch (e.key) {
			case "ArrowLeft":
				tempPosX = (tempPosX ?? slot.pos_x) - 1
				break
			case "ArrowRight":
				tempPosX = (tempPosX ?? slot.pos_x) + 1
				break
			case "ArrowDown":
				tempPosY = (tempPosY ?? slot.pos_y) + 1
				break
			case "ArrowUp":
				tempPosY = (tempPosY ?? slot.pos_y) - 1
				break
		}
	}

	function onKeyUp() {
		isDragging = false

		if (tempPosX !== undefined || tempPosY !== undefined) {
			GroupEditorController.Slots.updateSlot({
				pos_x: tempPosX ?? slot.pos_x,
				pos_y: tempPosY ?? slot.pos_y,
			})
		}

		tempPosX = undefined
		tempPosY = undefined

		setTimeout(() => {
			GroupCanvasController.refreshZoom()
		})
	}

	function updateListeners(selected: boolean) {
		const clearListeners = () => {
			document.removeEventListener("keyup", onKeyUp)
			document.removeEventListener("keydown", onKeyDown)
		}

		const applyListeners = () => {
			document.addEventListener("keyup", onKeyUp)
			document.addEventListener("keydown", onKeyDown)
		}

		if (selected) applyListeners()
		else clearListeners()
	}

	function getDevice(slotData: DeviceGroupSlot) {
		if (!slotData) return undefined

		const dev = DataHandlerDevice.get(slotData.device_id) as DeviceRPi
		dev.getSnapshot().then((snap) => {
			snapshot = snap
		})

		return dev
	}

	onDestroy(() => updateListeners(false))

	$effect(() => {
		updateListeners(isSelected)
	})
</script>

<div
	class="slot-instance {isSelected ? 'selected' : ''} {isDragging ? 'dragging' : ''}"
	id="slot-instance-{slot.device_id}-{slot.id}"
	onclick={() => onClick()}
	onmousedown={handleSlotMouseDown}
	bind:this={element}
	style="background-color: {device?.color}44; --color-border: {device?.color}; width: {slot.width *
		slot.scale_x}px; height: {slot.height * slot.scale_y}px; left: {isDragging
		? tempPosX
		: slot.pos_x}px; top: {isDragging ? tempPosY : slot.pos_y}px; z-index: {isSelected
		? 250
		: 5 * (slot.priority ?? 1)};"
>
	<span class="device-name">
		{#if isPositionLocked}
			<!-- <div class="position-locked-indicator"> -->
			<LockIcon isLocked={isPositionLocked} />
			<!-- </div> -->
		{/if}{device?.name ?? "Unassigned"}</span
	>
	{#if snapshot && showSnapshots}
		<img src={snapshot.src} alt="" />
	{:else if gridImage}
		<img src={gridImage} alt="" />
	{/if}
</div>

<style>
	.slot-instance {
		user-select: none;
		position: absolute;
		border: 5px solid var(--color-border);
		transition:
			left 500ms,
			top 500ms,
			height 500ms,
			width 500ms;
	}

	.slot-instance.selected {
		outline: 5px solid var(--color-border);
		opacity: 0.75;
	}

	.slot-instance.dragging {
		transition: none;
	}

	.device-name {
		font-size: var(--text-h1);
		position: absolute;
		bottom: 0;
		right: 1rem;
		display: flex;
		flex-direction: row;
		align-items: center;
		color: var(--color-border);
	}

	img {
		height: 100%;
		width: 100%;
		pointer-events: none;
	}

	.position-locked-indicator {
		position: absolute;
		top: 0;
		left: 0;
		bottom: 0;
		right: 0;
		margin: auto;
		pointer-events: none;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
	}

	.device-name :global(svg) {
		height: var(--text-h1);
		width: auto;
	}
	.device-name :global(.svg-fill) {
		fill: var(--color-border);
	}
</style>
